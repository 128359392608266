import { GET_ME, GET_ME_ERROR } from '../actions/getMe'

const initialState = {
  User: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ME:
      return {
        ...state,
        User: action.payload.data
      }

    case GET_ME_ERROR:
      return {
        ...state,
        User: null,
        error: action.payload
      }

    default:
      return state
  }
}
