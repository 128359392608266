import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../common/Button'
import Img from '../common/Img'

//Images
import LeftArrow from '../../assets/images/leftArrow16px.png'
import RightArrow from '../../assets/images/rightArrow16px.png'

const pageCreator = (pagesNumber, setCurrentPage, currentPage) => {
  let pagesElement = []
  let previousDot = false
  let nextDot = false

  for (let i = 1; i <= pagesNumber; i++) {
    if (i === 1) {
      //Previous Page
      if (currentPage === 1) {
        //no previous
        pagesElement.push(
          <Img
            styleType='NoPagination'
            src={LeftArrow}
            direction='left'
            key={i + 200}
          >
            {' '}
          </Img>
        )
      } else {
        //previous
        pagesElement.push(
          <Img
            styleType='Pagination'
            src={LeftArrow}
            onClick={() => setCurrentPage(currentPage - 1)}
            key={i + 200}
          ></Img>
        )
      }
    }

    if (i === currentPage) {
      pagesElement.push(
        <Button
          styleType='CurrentPage'
          onClick={() => setCurrentPage(i)}
          key={i}
        >
          {i}
        </Button>
      )
    } else if (i === 1 || i === pagesNumber) {
      pagesElement.push(
        <Button
          styleType='Pagination'
          onClick={() => setCurrentPage(i)}
          key={i}
        >
          {i}
        </Button>
      )
      //if number of page is under 7 , print them all
    } else if (pagesNumber <= 5) {
      pagesElement.push(
        <Button
          styleType='Pagination'
          onClick={() => setCurrentPage(i)}
          key={i}
        >
          {i}
        </Button>
      )
      // print only 7 pages (two before and after current)
    } else {
      if (i === currentPage - 1 || i === currentPage + 1) {
        pagesElement.push(
          <Button
            styleType='Pagination'
            onClick={() => setCurrentPage(i)}
            key={i}
          >
            {i}
          </Button>
        )
      } //show three dot if more than 7 pages
      if (currentPage - 1 > 1 && currentPage - 1 !== 2 && !previousDot) {
        previousDot = true
        pagesElement.push(
          <Button styleType='DotPagination' key={i + 100}>
            ...
          </Button>
        )
      }
      if (
        i === pagesNumber - 1 &&
        currentPage + 1 < pagesNumber &&
        currentPage + 1 !== pagesNumber - 1 &&
        !nextDot
      ) {
        nextDot = true
        pagesElement.push(
          <Button styleType='DotPagination' key={i + 100}>
            ...
          </Button>
        )
      }
    }

    if (i === pagesNumber) {
      //Next Page
      if (currentPage === pagesNumber) {
        //no next
        pagesElement.push(
          <Img
            styleType='NoPagination'
            src={RightArrow}
            direction='right'
            key={i + 200}
          ></Img>
        )
      } else {
        //next
        pagesElement.push(
          <Img
            styleType='Pagination'
            src={RightArrow}
            onClick={() => setCurrentPage(currentPage + 1)}
            key={i + 200}
          ></Img>
        )
      }
    }
  }

  return pagesElement
}

const Pagination = props => {
  const { total, setCurrentPage, perPage, currentPage } = props
  const [pages, setPages] = useState(0)
  useEffect(() => {
    const numberPages = total / perPage
    setPages(Math.ceil(numberPages))
  }, [total, perPage])

  if (total === 0 || pages === 1) {
    return null
  }
  return (
    <PaginationContainer>
      {pageCreator(pages, setCurrentPage, currentPage)}
    </PaginationContainer>
  )
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired
}
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${({ theme }) => theme.margin.large} 0;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    margin: ${({ theme }) => theme.margin.medium} 0;
  }

  @media ${({ theme }) => theme.device.large},
    ${({ theme }) => theme.device.medium} {
    margin: ${({ theme }) => theme.margin.mediumlarge} 0;
  }
`
export default Pagination
