import Axios from 'axios'

// Liste des types
export const DELETE_WEBINAR = 'DELETE_WEBINAR'
export const DELETE_WEBINAR_ERROR = 'DELETE_WEBINAR_ERROR'

export const deleteWebinar = (_id, t, enqueueSnackbar, history) => {
  return async dispatch => {
    try {
      dispatch({
        type: DELETE_WEBINAR_ERROR,
        payload: null
      })

      const options = {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_DELETE_VIMEO_TOKEN}`
        },
        data: {},
        url: `${process.env.REACT_APP_API_VIMEO_URL}videos/${_id}`
      }

      const { data } = await Axios(options)
      dispatch({
        type: DELETE_WEBINAR,
        payload: data
      })
      enqueueSnackbar(`${t('deleteAlert')}`, {
        variant: 'success'
      })
      history.push('/home')
    } catch (error) {
      const errorDetails = {
        status: error.response.status,
        statusText: error.response.statusText,
        apiMessage: error.response.data.message
      }

      dispatch({
        type: DELETE_WEBINAR_ERROR,
        payload: errorDetails
      })
    }
  }
}
