import { GET_WEBINAR, GET_WEBINAR_ERROR } from '../actions/getWebinar'

const initialState = {
  webinars: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WEBINAR:
      return {
        ...state,
        webinars: action.payload
      }

    case GET_WEBINAR_ERROR:
      return {
        ...state,
        webinars: null,
        error: action.payload
      }

    default:
      return state
  }
}
