import {
  UPDATE_PASSWORD_INFORMATION,
  UPDATE_PASSWORD_INFORMATION_ERROR
} from '../actions/updatePassword'

const initialState = {
  response: null,
  newUser: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_INFORMATION:
      return {
        ...state,
        newUser: action.payload.newUser,
        response: action.payload.message
      }

    case UPDATE_PASSWORD_INFORMATION_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload
      }

    default:
      return state
  }
}
