import { REGISTER_USER, REGISTER_USER_ERROR } from '../actions/register'

const initialState = {
  res: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        res: action.payload
      }
    case REGISTER_USER_ERROR:
      return {
        ...state,
        res: null,
        error: action.payload
      }
    default:
      return state
  }
}
