import Axios from 'axios'

// Liste des types
export const GET_ALL_USER = 'GET_ALL_USER'
export const GET_ALL_USER_ERROR = 'GET_ALL_USER_ERROR'

// Change le thème
export const getAllUser = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ALL_USER_ERROR,
        payload: null
      })

      const token = getState().authentication.token
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {},
        url: `${process.env.REACT_APP_API_BASE_URL}/users`
      }

      const { data } = await Axios(options)

      dispatch({
        type: GET_ALL_USER,
        payload: data
      })
    } catch (error) {
      const errorDetails = {
        status: error.response?.status,
        statusText: error.response?.statusText
      }

      dispatch({
        type: GET_ALL_USER_ERROR,
        payload: errorDetails
      })
    }
  }
}
