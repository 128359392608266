import React from 'react'
import { Redirect, Route } from 'react-router-dom'

//redux
import { useSelector } from 'react-redux'

//components
import PageLoader from '../../components/common/PageLoader'

//snackbar
import { useSnackbar } from 'notistack'

//translation
import { useTranslation } from 'react-i18next'

//token
const jwt = require('jsonwebtoken')

//Redirection sur Login en cas de non token
const PrivateRoute = ({ path, component: Component, right, ...rest }) => {
  //decode token
  const auth = useSelector(state => state.authentication)

  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()

  let access = null
  let decoded = null

  //decode token
  try {
    decoded = jwt.verify(auth?.token, process.env.REACT_APP_JWT_SECRET)
  } catch (error) {
    //token is expired
    if (error.name === 'TokenExpiredError') {
      //delete the token
      auth.token = null
      enqueueSnackbar(t('tokenExpired'), {
        variant: 'error'
      })
      //use action logout create two rendering at the same time
    }
  }

  switch (right) {
    case 'user':
      if (decoded != null) {
        access = true
      } else {
        access = null
      }

      break

    case 'publisher':
      if (decoded != null) {
        if (decoded?.role === 'admin' || decoded?.role === 'publisher') {
          access = true
        } else {
          access = false
        }
      } else {
        access = null
      }
      break

    case 'admin':
      if (decoded != null) {
        if (decoded?.role === 'admin') {
          access = true
        } else {
          access = false
        }
      } else {
        access = null
      }
      break

    default:
      break
  }

  return (
    <Route
      {...rest}
      render={props =>
        //not connected
        auth.token === null ? (
          //not connected
          <Redirect to='/signin'></Redirect>
        ) : access === null ? ( //token is not decoded
          <PageLoader />
        ) : access === true ? ( //role is correct
          <Component {...props} token={decoded} />
        ) : (
          //not authorized
          <Redirect to='/signin'></Redirect>
        )
      }
    ></Route>
  )
}

export default PrivateRoute
