import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import reducers from './reducers'

const middlewares = [thunk]

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`)
  middlewares.push(logger)
}

// Middleware: Redux Persist Config
const persistConfig = {
  // Root?
  key: 'webinar',
  // Storage Method (React Native)
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ['authentication', 'getOneWebinar'],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    'register',
    'getWebinar',
    'deleteWebinar',
    'updateWebinar',
    'getAllUser',
    'updateRole',
    'resetPassword',
    'forgetPassword',
    'updatePassword',
    'getOneUser'
  ],
  // There is an issue in the source code of redux-persist (default setTimeout does not cleaning)
  timeout: null
}

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, reducers)

// Redux: Store
// const store = createStore(persistedReducer, applyMiddleware([logger, thunk]))
const store = compose(applyMiddleware(...middlewares))(createStore)(
  persistedReducer
)

// Middleware: Redux Persist Persister
let persistor = persistStore(store)

export { store, persistor }
