import React, { useState } from 'react'
import styled from 'styled-components'

//components
import Input from '../common/Input'
import Label from '../common/Label'
import Button from '../common/Button'
import ErrorText from '../common/ErrorText'
import InputContainer from '../common/InputContainer'

//redux
import * as Actions from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//translation
import { useTranslation } from 'react-i18next'

//snackbar
import { useSnackbar } from 'notistack'

//regex
import { nameRegex } from '../../config/regex'

const ProfilForm = props => {
  let { user, token } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const [firstname, setFirstname] = useState({
    value: '',
    isError: false,
    isErrorMessage: '',
    changed: false
  })

  const [lastname, setLastname] = useState({
    value: '',
    isError: false,
    isErrorMessage: '',
    changed: false
  })

  const checkLastname = lastname => {
    if (lastname.value === '') {
      setLastname({
        ...lastname,
        isErrorMessage: 'fieldRequired',
        isError: true
      })
    } else if (!nameRegex.test(lastname.value)) {
      setLastname({
        ...lastname,
        isErrorMessage: 'textError',
        isError: true
      })
    } else {
      setLastname({
        ...lastname,
        isError: false,
        isErrorMessage: ''
      })
    }
  }

  const checkFirstname = firstname => {
    if (firstname.value === '') {
      setFirstname({
        ...firstname,
        isErrorMessage: 'fieldRequired',
        isError: true
      })
    } else if (!nameRegex.test(firstname.value)) {
      setFirstname({
        ...firstname,
        isErrorMessage: 'textError',
        isError: true
      })
    } else {
      setFirstname({
        ...firstname,
        isError: false,
        isErrorMessage: ''
      })
    }
  }

  //update user information
  const updateUser = e => {
    e.preventDefault()

    //check conform input
    if (!firstname.isError && !lastname.isError) {
      //update user informations
      dispatch(
        Actions.updateUser(
          firstname,
          lastname,
          token?.id,
          enqueueSnackbar,
          t,
          history
        )
      )
    } else {
      enqueueSnackbar(`${t('incorrectInput')}`, {
        variant: 'error'
      })
    }
  }

  return (
    <ProfilFormContainer>
      <InputContainer styleType='Profil'>
        <Label styleType='Primary'>{t('lastNameLabel')}</Label>
        <Input
          styleType={'Primary'}
          onChange={e =>
            setLastname({
              ...lastname,
              value: e.target.value,
              changed: true
            })
          }
          onBlur={() => checkLastname(lastname)}
          defaultValue={user?.User.lastname}
          placeholder={t('lastNamePlaceholder')}
        ></Input>
        {lastname.changed && lastname.isError ? (
          <ErrorText>{t(lastname.isErrorMessage)}</ErrorText>
        ) : null}
      </InputContainer>

      <InputContainer styleType='Profil'>
        <Label styleType='Primary'>{t('firstNameLabel')}</Label>
        <Input
          styleType={'Primary'}
          onChange={e =>
            setFirstname({
              ...firstname,
              value: e.target.value,
              changed: true
            })
          }
          onBlur={() => checkFirstname(firstname)}
          defaultValue={user?.User.firstname}
          placeholder={t('firstNamePlaceholder')}
        ></Input>
        {firstname.changed && firstname.isError ? (
          <ErrorText>{t(firstname.isErrorMessage)}</ErrorText>
        ) : null}
      </InputContainer>

      <InputContainer styleType='Profil'>
        <Label styleType='Primary'>{t('emailLabel')}</Label>
        <Input styleType='Disabled' value={user?.User.email}></Input>
      </InputContainer>

      <InputContainer styleType='Profil'>
        <Label styleType='Primary'>{t('roleLabel')}</Label>
        <Input styleType='Disabled' value={t(user?.User.role)}></Input>
      </InputContainer>

      <Button styleType='Primary' onClick={e => updateUser(e)} type='submit'>
        {t('updateButton')}
      </Button>
      <Button styleType='Primary' onClick={() => history.push('/newpassword')}>
        {t('GoToPassword')}
      </Button>
    </ProfilFormContainer>
  )
}

const ProfilFormContainer = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

export default ProfilForm
