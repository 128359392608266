import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Title = props => {
  const { styleType, children } = props

  const selectTitle = children => {
    switch (styleType) {
      case 'Webinar':
        return <WebinarTitle>{children}</WebinarTitle>

      case 'Auth':
        return <AuthTitle>{children}</AuthTitle>

      default:
        return <BaseTitle>{children}</BaseTitle>
    }
  }
  return selectTitle(children)
}

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  styleType: PropTypes.string
}

const BaseTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontsize.xlarge};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.margin.mediumsmall};
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    font-size: ${({ theme }) => theme.fontsize.xmlarge};
    line-height: ${({ theme }) => theme.lineHeight.large};
    margin-bottom: ${({ theme }) => theme.margin.xsmall};
  }

  @media ${({ theme }) => theme.device.medium}, {
    font-size: ${({ theme }) => theme.fontsize.large};
    line-height: ${({ theme }) => theme.lineHeight.large};
    margin-bottom: ${({ theme }) => theme.margin.small};
  }
`

const WebinarTitle = styled(BaseTitle)`
  line-height: ${({ theme }) => theme.lineHeight.xlarge};
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    line-height: ${({ theme }) => theme.lineHeight.large};
  }
`

const AuthTitle = styled(BaseTitle)`
  font-size: ${({ theme }) => theme.fontsize.xmlarge};
  color: ${({ theme }) => theme.quaternaryTextColor};
  padding-bottom: ${({ theme }) => theme.padding.small};
  margin-bottom: 0;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    font-size: ${({ theme }) => theme.fontsize.mediumlarge};
    padding-bottom: ${({ theme }) => theme.padding.xxsmall};
    line-height: ${({ theme }) => theme.lineHeight.medium};
  }
`

export default Title
