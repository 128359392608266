import Axios from 'axios'

// Liste des types
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR'

export const updateRole = (role, _id, t, enqueueSnackbar, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_ROLE_ERROR,
        payload: null
      })

      const token = getState().authentication.token
      const options = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          role: role
        },
        url: `${process.env.REACT_APP_API_BASE_URL}/users/${_id}`
      }

      const { data } = await Axios(options)
      dispatch({
        type: UPDATE_ROLE,
        payload: data
      })

      enqueueSnackbar(`${t('alertRoleChanged')}`, {
        variant: 'success'
      })

      history.push('/admin')
    } catch (error) {
      const errorDetails = {
        status: error.response.status,
        statusText: error.response.statusText
      }

      if (error.response.data.message === 'tokenExpired') {
        history.push('/signin')
      }

      enqueueSnackbar(t('Unauthorized'), {
        variant: 'error'
      })

      dispatch({
        type: UPDATE_ROLE_ERROR,
        payload: errorDetails
      })
    }
  }
}
