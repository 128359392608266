import React, { useState } from 'react'
import styled from 'styled-components'

//components
import Input from '../common/Input'
import Label from '../common/Label'
import Button from '../common/Button'
import ErrorText from '../common/ErrorText'
import TextArea from '../common/TextArea'
import InputContainer from '../common/InputContainer'
import Modal from '../common/Modal'

//redux
import * as Actions from '../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//translation
import { useTranslation } from 'react-i18next'

import { useSnackbar } from 'notistack'

//regex
import { webinarTitleRegex, webinarDescriptionRegex } from '../../config/regex'

const EditWebinarForm = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const webinarResponse = useSelector(state => state.getOneWebinar)

  //recupérer l'id depuis la state
  const splitpath = webinarResponse.response.uri.split('/')
  const webinarId = splitpath[2]

  const [title, setTitle] = useState({
    value: '',
    isError: false,
    isErrorMessage: '',
    changed: false
  })

  const [description, setDescription] = useState({
    value: '',
    isError: false,
    isErrorMessage: '',
    changed: false
  })

  const checkTitle = title => {
    if (title.value === '') {
      setTitle({
        ...title,
        isErrorMessage: 'fieldRequired',
        isError: true
      })
    } else if (!webinarTitleRegex.test(title.value)) {
      setTitle({
        ...title,
        isErrorMessage: 'titleError',
        isError: true
      })
    } else {
      setTitle({
        ...title,
        isError: false,
        isErrorMessage: ''
      })
    }
  }

  const checkDescription = description => {
    if (!webinarDescriptionRegex.test(description.value)) {
      setDescription({
        ...description,
        isErrorMessage: 'titleError',
        isError: true
      })
    } else {
      setDescription({
        ...description,
        isError: false,
        isErrorMessage: ''
      })
    }
  }

  //action update
  const updateWebinar = e => {
    e.preventDefault()

    //check conform input
    if (!title.isError && !description.isError) {
      //check if user make changes
      let titleSend = ''
      let descriptionSend = ''
      //title
      if (title.changed === true) {
        titleSend = title.value
      } else {
        // no change
        titleSend = webinarResponse.response.name
      }
      //description
      if (description.changed === true) {
        descriptionSend = description.value
      } else {
        // no change
        descriptionSend = webinarResponse.response.description
      }

      //update title and description
      dispatch(
        Actions.updateWebinar(
          titleSend,
          descriptionSend,
          webinarId,
          enqueueSnackbar,
          t,
          history
        )
      )
    } else {
      enqueueSnackbar(`${t('incorrectInput')}`, {
        variant: 'error'
      })
    }
  }

  //delete webinar
  const deleteWebinar = e => {
    e.preventDefault()

    //delete webinar
    dispatch(Actions.deleteWebinar(webinarId, t, enqueueSnackbar, history))
  }

  const [openModal, setOpenModal] = useState(false)

  return (
    <EditWebinarContainer>
      <InputContainer>
        <Label styleType='Primary'>{t('titleLabel')}</Label>
        <Input
          styleType='Primary'
          onChange={e =>
            setTitle({
              ...title,
              value: e.target.value,
              changed: true
            })
          }
          onBlur={() => checkTitle(title)}
          defaultValue={webinarResponse.response?.name}
          placeholder={t('titlePlaceholder')}
        ></Input>
        {title.changed && title.isError ? (
          <ErrorText>{t(title.isErrorMessage)}</ErrorText>
        ) : null}
      </InputContainer>

      <InputContainer>
        <Label styleType='Primary'>{t('descriptionLabel')}</Label>
        <TextArea
          onChange={e =>
            setDescription({
              ...description,
              value: e.target.value,
              changed: true
            })
          }
          onBlur={() => checkDescription(description)}
          defaultValue={webinarResponse.response?.description}
          placeholder={t('descriptionPlaceholder')}
        ></TextArea>

        {description.changed && description.isError ? (
          <ErrorText>{t(description.isErrorMessage)}</ErrorText>
        ) : null}
      </InputContainer>
      <ButtonContainer>
        <Button styleType='Primary' onClick={e => updateWebinar(e)}>
          {t('updateButton')}
        </Button>
        <Button
          styleType='Primary'
          onClick={() => setOpenModal(true)}
          type='delete'
        >
          {t('deleteButton')}
        </Button>
      </ButtonContainer>

      <Modal setOpenModal={setOpenModal} openModal={openModal}>
        <Button styleType='Modal' onClick={e => setOpenModal(false)}>
          {t('abortButton')}
        </Button>
        <Button styleType='Modal' type='delete' onClick={e => deleteWebinar(e)}>
          {t('deleteButton')}
        </Button>
      </Modal>
    </EditWebinarContainer>
  )
}

const EditWebinarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 65%;
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.medium} {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default EditWebinarForm
