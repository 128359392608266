export default {
  translation: {
    // Navlinks
    navlien1: 'Webinars',
    navlien2: 'Profile',
    navlien3: 'Settings',
    navlien4: 'Log out',

    //Signup and Signin Form
    signUpTitle: 'Get access to quality content',
    signInTitle: 'Welcome back',
    signUpSubTitle: 'Enter your info to get started',
    signInSubTitle: 'Learn with us !',
    lastNameLabel: 'Lastname',
    lastNamePlaceholder: 'Martin',
    firstNameLabel: 'Firstname',
    firstNamePlaceholder: 'François',
    emailLabel: 'E-mail',
    emailPlaceholder: 'françois@example.com',
    passwordLabel: 'Password',
    signInButton: 'Login In',
    signUpButton: 'Create an account',
    linkToSignIn: 'Already registered ?',
    linkToSignInBold: 'Login in !',
    linkToSignUp: 'No account yet ?',
    linkToSignUpBold: 'Create one !',
    submitButton: 'Submit',
    forgetPassword: 'Forget password ?',
    loginAlert: 'Welcome back ',
    registerAlert: 'Account created',
    disconnectAlert: 'You have been disconnected',
    apiAuthNotOnline: 'Auth API is not online',

    //Error message
    emailError: 'Wrong email',
    registerError: 'Error while creating your account',
    mailAlreadyUsed: 'An account with that email already exist',
    401: 'Incorrect email or password',
    textError: 'Please enter only alphabetical letters',
    titleError: 'Please do not use special characters',
    fieldRequired: 'Input field required',
    titleTooLong: 'Please enter a shorter title',
    descriptionTooLong: 'Please enter a shorter description',
    tokenExpired: 'Your session has expired',
    unauthorizedRole:
      'Your current role does not allow you to get access to this content. Please reconnect',
    Unauthorized: 'An error occurred',

    //Home Form
    webinarDescription: "Webinars's description.............",
    placeHolderWebinar: 'Search for a webinar',
    createWebinarButton: 'Create a webinar',
    noResult: 'No webinar macthes the query',
    webinars: 'Webinars',
    seeMore: 'See more',

    //Webinar Form
    titleLabel: 'Title',
    titlePlaceholder: 'Example of webinar...',
    descriptionLabel: 'Description',
    descriptionPlaceholder: "Webinar's description",
    editLabel: 'Editing a webinar',
    editButton: 'Edit',
    deleteButton: 'Delete',
    updateButton: 'Update',
    GoToPassword: 'Change your password',
    ErrorAreaEmpty: 'Please fill in all area',
    webinarEdit: 'You are about to modify a webinar',
    deleteConfirm: 'Are you sure you wish to delete this webinar?',
    deleteAlert: 'Webinar deleted',
    updateAlert: 'Webinar updated',
    updateError: 'Title is required',
    incorrectInput: 'One or more textfield is wrong ',
    noWebinarFound: 'Webinar does not exist',
    abortButton: 'Close',
    deleteWebinar: 'Deleting a webinar',
    UnableToGetUsers: 'Unable to get Users',

    //Profil Form
    profilLabel: 'Your Profile',
    profilDesc: 'You can modify the information not grayed out',
    roleLabel: 'Role',
    newPassword: 'New password',
    lastPassword: 'Last password',
    notCorrectLastPassword: 'Incorrect last password',
    notCorrectNewPassword: 'Incorrect new password',
    publisher: 'Publisher',
    admin: 'Admin',
    user: 'User',
    alertUpdate: 'Profile Updated',

    //Password match:
    passwordRule1: '8 to 32 characters',
    passwordRule2: 'One uppercase letter',
    passwordRule3: 'One lowercase letter',
    passwordRule4: 'One number',
    passwordRule5: 'One special character',

    //AdminInterfaceForm
    adminLabel: 'User Management',
    adminDesc: 'You can consult the different users',
    placeHolderSearch: 'Search for a user',

    //UserRoleForm
    roleTitle: 'Role management',
    roleDesc: 'You can change the role of the user',
    roleNotConform: 'Please select a role',
    idNotConform: 'Please select a user',
    fillInArea: 'Please fill in all area',
    alertRoleChanged: 'User updated',
    noUserFound: 'User does not exist',

    //ResetPasswordForm
    resetLabel: 'Reset password',
    ErrorPasswordReset: 'Error while updating your password',
    invalidToken: 'Access not granted',
    resetAlert: 'Your password has been updated',
    resetDesc: 'Please enter a new password',

    //ForgetPasswordForm
    forgetLabel: 'Password reset',
    mailLabel: 'Please enter your email adress',
    forgetAlert: 'You have received an email to reset your password',
    noUserEmail: 'There is no user with this email',
    noEmailSent: 'Email could not been sent',

    //NotFound
    errorLabel: 'It seems that you are lost',
    returnHome: 'You can return to the page',
    returnHomeBold: 'Webinar',
    returnSignIn: 'You can return to the page',
    returnSignInBold: 'Sign in',
    error404: '404',
    errorTokenVerify: 'An error has occurred',

    //Table
    nextButton: 'Next',
    previousButton: 'Previous',

    //Policy
    policyTitle: 'Privacy policy',
    preference: 'Your preferences',
    textTitle1: 'Introduction',
    text1:
      'Within the scope of his activity, the society Izencia, which the headquarters is located at 5 avenue de Verdun in Ivry sur seine (94200), is brought to collect and process data wich certain are qualified as « personal data ». Izencia show an interest in the respect of privacy, and only use data in a responsable and confidential way and in a precise purpose.',
    textTitle2: 'Personal data',
    text2a:
      'On the website (NOM DE DOMAINE A DEFINIR), there is two types of data that can likely be gathered :',
    text2b: 'The data directly transmitted',
    text2c:
      'These data are those you directly send us, by filling the registration form. It means your lastname, firstmane and your mail.',
    text2d: 'The data colleted automatically',
    text2e:
      'During you visit, after you give your consent, we could collect « web analytics » informations relating to your navigation, the duration of your consultation, you IP adress, your type and version of browser. The technologie used is the cookie.',
    textTitle3: 'Data usage',
    text3:
      "The data you directly send are used to get back in contact with you and/or in the surroundings of your demand you have done. The data « web analytics » are collected anonymously (by saving anonymous IP adress) by Google Analytics, and let us estimate the audience of our website, consultations and potentials error in order to improve the user's experience. These data are used by Izencia, responsible for data processing, and will never be given to a third or used to other end than those are mentioned above.",
    textTitle4: 'Legal basis',
    text4:
      'The personal data are collected after the obligatory consent of the user. This consent is clearly, free and unambiguous collected (button and checkbox).',
    textTitle5: 'Retention period',
    text5: 'The data saved will be keeped during a maximum of three years.',
    textTitle6: 'Cookies',
    text6a: 'Here is the list of cookies used and their objective :',
    text6b: "Cookie's lifetime don't exceed 13 month",
    text6c: 'Cookies Google Analytics (exhaustive list) : Web analytics',
    text6d: 'Vimeo : Allow access to our videos',
    textTitle7: 'Your rights regarding personal data',
    text7:
      'You have the right to consult, modify or delete the whole of you personal data. You can also withdraw your consent about the processing of your data.',
    link7: 'Change your cookie preferences',
    textTitle8: 'Data Protection Office contact',
    text8: 'Contact – DPO – dpo@izencia.com'
  }
}
