import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

//components
import Label from '../common/Label'
import Input from '../common/Input'
import Img from '../common/Img'
import Button from '../common/Button'
import MyLink from '../common/MyLink'
import ErrorText from '../common/ErrorText'
import InputContainer from '../common/InputContainer'
import PasswordContainer from '../common/PasswordContainer'

//redux
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../redux/actions'

//translation
import { useTranslation } from 'react-i18next'

//snackbar
import { useSnackbar } from 'notistack'

//images
import Visibility from '../../assets/images/visibility40px40px.png'
import NoVisibility from '../../assets/images/noVisibility40px40px.png'

//regex
import { emailRegex } from '../../config/regex'

const SignInForm = props => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  //check if already connected
  const userToken = useSelector(state => state.authentication?.token)

  useEffect(() => {
    if (userToken) {
      history.push('/home')
    }
  }, [userToken, history])

  const [email, setEmail] = useState({
    value: '',
    isError: false,
    isErrorMessage: ''
  })

  const [password, setPassword] = useState({
    value: ''
  })

  //show or hide password
  const [displayPassword, setDisplayPassword] = useState(false)

  //input for showing password
  const passwordInput = {
    type: displayPassword ? 'text' : 'password'
  }

  //check
  const checkMail = value => {
    return !emailRegex.test(value)
  }

  //Action
  const connectUser = e => {
    e.preventDefault()
    //check conform input
    if (!email.isError) {
      dispatch(
        Actions.login(email.value, password.value, enqueueSnackbar, t, history)
      )
    } else {
      enqueueSnackbar(`${t('incorrectInput')}`, {
        variant: 'error'
      })
    }
  }

  return (
    <SignInContent>
      <InputContainer>
        <Label styleType='Primary'>{t('emailLabel')}</Label>
        <Input
          styleType='Auth'
          onChange={e =>
            setEmail({
              ...email,
              value: e.target.value
            })
          }
          onBlur={e =>
            checkMail(email.value)
              ? setEmail({
                  ...email,
                  isError: true,
                  isErrorMessage: t('emailError')
                })
              : setEmail({
                  ...email,
                  isError: false,
                  isErrorMessage: ''
                })
          }
          placeholder={t('emailPlaceholder')}
        />
        {email.isError ? <ErrorText>{email.isErrorMessage}</ErrorText> : null}
      </InputContainer>
      <InputContainer>
        <Label styleType='Primary'>{t('passwordLabel')}</Label>
        <PasswordContainer>
          <Input
            styleType='Password'
            onChange={e =>
              setPassword({
                ...password,
                value: e.target.value
              })
            }
            inputCfg={passwordInput}
          ></Input>
          {displayPassword === true ? (
            <Img
              styleType='Eye'
              src={Visibility}
              onClick={() => setDisplayPassword(!displayPassword)}
            ></Img>
          ) : (
            <Img
              styleType='Eye'
              src={NoVisibility}
              onClick={() => setDisplayPassword(!displayPassword)}
            ></Img>
          )}
        </PasswordContainer>
      </InputContainer>
      <MyLink styleType='ForgetPassword' to='/forgetpassword'>
        {t('forgetPassword')}
      </MyLink>
      <Button type='submit' onClick={e => connectUser(e)} styleType={'Auth'}>
        {t('signInButton')}
      </Button>
    </SignInContent>
  )
}

const SignInContent = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
  }
`

export default SignInForm
