import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Img from '../Img'

import PurpleArrow from '../../../assets/images/purpleArrow30px30px.png'

const Text = props => {
  const { styleType, children, type } = props
  const selectText = children => {
    switch (styleType) {
      case 'SeeMore':
        return (
          <SeeMoreContainer>
            <SeeMoreText>{children}</SeeMoreText>
            <Img styleType='SeeMore' src={PurpleArrow}></Img>
          </SeeMoreContainer>
        )

      case 'Card':
        return <CardText>{children}</CardText>

      case 'Modal':
        return <ModalText>{children}</ModalText>

      case 'ApiError':
        return <ApiErrorText>{children}</ApiErrorText>

      case 'SnackBar':
        return <SnackBarText type={type}>{children}</SnackBarText>

      case 'Policy':
        return <PolicyText type={type}>{children}</PolicyText>
      default:
        return <BaseText>{children}</BaseText>
    }
  }
  return selectText(children)
}

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  styleType: PropTypes.string
}

const BaseText = styled.p``

const CardText = styled(BaseText)`
  line-height: ${({ theme }) => theme.lineHeight.small};
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontsize.small};
  text-align: justify;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margin.xsmall};

  /*limit length text */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`

const ModalText = styled(BaseText)`
  color: ${({ theme }) => theme.primaryTextColor};
  font-size: ${({ theme }) => theme.fontsize.small};
  text-align: left;
  margin-bottom: ${({ theme }) => theme.margin.small};
`

const SeeMoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.padding.xxsmall};
`

const SeeMoreText = styled(BaseText)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`

const ApiErrorText = styled(BaseText)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontsize.medium};
  margin-top: 4rem;
  text-align: center;
`

const SnackBarText = styled(BaseText)`
  padding: 10px;
  color: ${({ theme }) => theme.noColor};
`

const PolicyText = styled(BaseText)`
  line-height: ${({ theme }) => theme.lineHeight.medium};
  text-align: justify;
  margin-bottom: ${({ theme, type }) =>
    type === 'bold' ? theme.margin.xsmall : theme.margin.medium};
  font-weight: ${({ theme, type }) => (type === 'bold' ? 'bold' : 'normal')};
`

export default Text
