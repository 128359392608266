import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Select = props => {
  const { onChange, defaultValue } = props

  const { t } = useTranslation()
  return (
    <BaseSelect onChange={onChange} defaultValue={defaultValue}>
      {defaultValue === 'user' ? (
        <option defaultValue='selected' value='user'>
          {t('user')}
        </option>
      ) : (
        <option value='user'>{t('user')}</option>
      )}
      {defaultValue === 'publisher' ? (
        <option defaultValue='selected' value='publisher'>
          {t('publisher')}
        </option>
      ) : (
        <option value='publisher'>{t('publisher')}</option>
      )}
      {defaultValue === 'admin' ? (
        <option defaultValue='selected' value='admin'>
          {t('admin')}
        </option>
      ) : (
        <option value='admin'>{t('admin')}</option>
      )}
    </BaseSelect>
  )
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired
}

const BaseSelect = styled.select`
  margin-top: ${({ theme }) => theme.margin.xxsmall};
  padding: ${({ theme }) => theme.padding.xsmall}
    ${({ theme }) => theme.padding.small};

  font-size: ${({ theme }) => theme.fontsize.small};

  color: ${({ theme }) => theme.secondaryTextColor};
  border-radius: ${({ theme }) => theme.borderRadius.small};

  cursor: pointer;

  /* reset preset*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: 0;
  :hover {
    box-shadow: none;
  }
`

export default Select
