import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Card from '../Card'

const CardContainer = props => {
  const { webinarSuccess } = props
  return (
    <BaseCardContainer>
      {webinarSuccess.webinars.data.map(res => (
        <Card
          _id={res.uri.substring(
            res.uri.length - 9
          )} /* delete /videos/ from string*/
          name={res.name}
          description={res.description}
          img_src={res.pictures.sizes[3].link}
          key={res.uri}
        ></Card>
      ))}
    </BaseCardContainer>
  )
}

CardContainer.protoTypes = {
  webinarSuccess: PropTypes.object
}

const BaseCardContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  display: grid;
  margin-top: ${({ theme }) => theme.margin.xlarge};
  grid-template-columns: 31% 31% 31%;
  grid-column-gap: 3.5%;
  grid-row-gap: ${({ theme }) => theme.margin.xlarge};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    grid-template-columns: 100%;
    grid-row-gap: ${({ theme }) => theme.margin.mediumlarge};
    margin-top: ${({ theme }) => theme.margin.mediumlarge};
  }

  @media ${({ theme }) => theme.device.large},
    ${({ theme }) => theme.device.medium} {
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
    grid-row-gap: ${({ theme }) => theme.margin.large};
    margin-top: ${({ theme }) => theme.margin.large};
  }
`
export default CardContainer
