export const commonTheme = {
  colors: {
    white: '#FFFFFF',
    lightGray: '#F4F6F8',
    blue: '#006391',
    purple: '#241545',
    green: '#18AC85',
    darkGray: '#7B7986',
    red: '#E13636'
  },
  marges: {
    small: '0.5em',
    medium: '1em',
    large: '1.5em',
    xlarge: '3em'
  },
  fontsize: {
    xsmall: '0.625rem',
    mediumsmall: '0.8rem',
    small: '1rem',
    medium: '1.25rem',
    mediumlarge: '1.5rem',
    xmlarge: '1.75rem',
    large: '2rem',
    xlarge: '2.5rem',
    xxlarge: '3rem'
  },
  headerHeight: '5.5rem', // utilisé
  phoneHeaderHeight: '4rem', // utilisé
  footerHeight: '5rem', //utilisé
  radius: {
    main: '0.25em'
  },
  device: {
    xsmall: `screen and (max-width: 575px)`,
    small: `screen and (min-width: 576px) and (max-width: 767px)`,
    medium: `screen and (min-width: 768px) and (max-width: 991px)`,
    large: `screen and (min-width: 992px) and (max-width: 1199px)`,
    xlarge: `screen and (min-width: 1200px) and (max-width: 1439px)`,
    xxlarge: `screen and (min-width: 1440px)`
  },
  //new
  borderRadius: {
    xxsmall: '0.25rem',
    xsmall: '0.5rem',
    small: '0.625rem',
    medium: '1rem'
  },
  padding: {
    xxsmall: '0.5rem', //snackbar
    xsmall: '0.75rem',
    small: '1rem',
    mediumsmall: '1.25rem',
    medium: '1.5rem',
    mediumlarge: '2.5rem',
    large: '3.125rem',
    xlarge: '4rem',
    xxlarge: '5.5rem'
  },
  margin: {
    container: '7.125rem',
    xxsmall: '0.375rem',
    xsmall: '0.5rem',
    small: '1rem',
    mediumsmall: '1.25rem',
    medium: '1.5rem',
    mediumlarge: '2.5rem',
    large: '2.75rem',
    xlarge: '3.5rem',
    xxlarge: '4.5rem',
    xxxlarge: '6.5rem'
  },
  fontWeight: {
    semibold: '600'
  },
  lineHeight: {
    xsmall: '1rem',
    small: '1.2rem',
    medium: '1.5rem',
    large: '2rem',
    xlarge: '2.5rem'
  }
}
