import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

//components
import CardTitle from '../CardTitle'
import Text from '../Text'
import Img from '../Img'
import TitleContainer from '../TitleContainer'

import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

const Card = props => {
  const { _id, name, description, img_src } = props
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <CardContainer onClick={() => history.push(`/webinar/${_id}`)}>
      <Img styleType='Thumbnail' src={img_src}></Img>
      <CardContent>
        <TitleContainer styleType='Card'>
          <CardTitle styleType='Card'>{name}</CardTitle>
          <Text styleType='Card'>{description}</Text>
        </TitleContainer>
        <Text styleType='SeeMore'>{t('seeMore')}</Text>
      </CardContent>
    </CardContainer>
  )
}

Card.propTypes = {
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  img_src: PropTypes.string.isRequired
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: 24rem;
  background-color: ${({ theme }) => theme.noColor};
  padding: ${({ theme }) => theme.padding.small};
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.medium} {
    min-height: 20rem;
  }

  position: relative;
  top: 0;

  :hover {
    transition: all 0.3s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    background-color: ${({ theme }) => theme.noColor};
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: ${({ theme }) => theme.margin.mediumsmall};
`

export default Card
