import Axios from 'axios'

// Liste des types
export const GET_ONE_WEBINAR = 'GET_ONE_WEBINAR'
export const GET_ONE_WEBINAR_ERROR = 'GET_ONE_WEBINAR_ERROR'

export const getOneWebinar = _id => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_ONE_WEBINAR_ERROR,
        payload: null
      })

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VIMEO_TOKEN}`
        },
        data: {},
        url: `${process.env.REACT_APP_API_VIMEO_URL}videos/${_id}`
      }

      const { data } = await Axios(options)
      dispatch({
        type: GET_ONE_WEBINAR,
        payload: data
      })
    } catch (error) {
      const errorDetails = {
        status: error.response.status,
        statusText: error.response.statusText,
        apiMessage: error.response.data.message
      }
      dispatch({
        type: GET_ONE_WEBINAR_ERROR,
        payload: errorDetails
      })
    }
  }
}
