import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Input = props => {
  const {
    styleType,
    onChange,
    onBlur,
    defaultValue,
    placeholder,
    inputCfg,
    value
  } = props

  const selectInput = () => {
    switch (styleType) {
      case 'Primary':
        return (
          <PrimaryInput
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            placeholder={placeholder}
          ></PrimaryInput>
        )

      case 'Search':
        return (
          <SearchInput
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            placeholder={placeholder}
          ></SearchInput>
        )

      case 'Auth':
        return (
          <AuthInput
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
          ></AuthInput>
        )

      case 'Password':
        return (
          <PasswordInput
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            inputAttr={inputCfg}
          ></PasswordInput>
        )

      case 'Disabled':
        return <DisabledInput disabled value={value}></DisabledInput>

      case 'Table':
        return (
          <TableInput
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            placeholder={placeholder}
          ></TableInput>
        )
      default:
        return (
          <BaseInput
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            placeholder={placeholder}
          ></BaseInput>
        )
    }
  }
  return selectInput()
}

Input.propTypes = {
  styleType: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  inputCfg: PropTypes.object,
  value: PropTypes.string
}

const BaseInput = styled.input`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontsize.medium};
  line-height: ${({ theme }) => theme.lineHeight.xsmall};
  background-color: ${({ theme }) => theme.noColor};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.primaryTextColor};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  /* delete preset */
  border: none;
  &:focus {
    box-shadow: none;
    outline: 0;
  }
  ::placeholder {
    color: ${({ theme }) => theme.secondaryTextColor};
  }
`
const PrimaryInput = styled(BaseInput)`
  padding: ${({ theme }) => theme.padding.xsmall}
    ${({ theme }) => theme.padding.small};
  font-size: ${({ theme }) => theme.fontsize.small};
`

const SearchInput = styled(BaseInput)`
  padding: ${({ theme }) => theme.padding.small}
    ${({ theme }) => theme.padding.medium};
  margin-top: ${({ theme }) => theme.margin.medium};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    font-size: ${({ theme }) => theme.fontsize.small};
  }
`

const AuthInput = styled(PrimaryInput)`
  border: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor};
}
`

const PasswordInput = styled(AuthInput).attrs(({ inputAttr }) => ({
  ...inputAttr
}))`
  border: none;
`

const DisabledInput = styled(PrimaryInput)`
  background-color: ${({ theme }) => theme.quaternaryBackgroundColor}20;
  color: ${({ theme }) => theme.primaryTextColor}90;
`

const TableInput = styled(SearchInput)`
  width: 50%;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.margin.medium};
  border: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  padding: ${({ theme }) => theme.padding.xsmall}
    ${({ theme }) => theme.padding.small};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    width: 100%;
  }
`
export default Input
