import React from 'react'

//components
import EditWebinarForm from '../../components/EditWebinarForm'
import ScreenContainer from '../../components/common/ScreenContainer'
import TitleContainer from '../../components/common/TitleContainer'
import Title from '../../components/common/Titles/Title'
import SubTitle from '../../components/common/Titles/SubTitle'

//translation
import { useTranslation } from 'react-i18next'

const EditWebinar = props => {
  const { token } = props
  const { t } = useTranslation()

  return (
    <ScreenContainer token={token}>
      <TitleContainer styleType='Page'>
        <Title>{t('editLabel')}</Title>
        <SubTitle styleType='Primary'>{t('webinarEdit')}</SubTitle>
      </TitleContainer>
      <EditWebinarForm token={token}></EditWebinarForm>
    </ScreenContainer>
  )
}

export default EditWebinar
