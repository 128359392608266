import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'

const ErrorText = props => {
  const { styleType, children } = props
  const selectError = children => {
    switch (styleType) {
      case 'NotFound':
        return <NotFoundError>{children}</NotFoundError>

      default:
        return <BaseError>{children}</BaseError>
    }
  }
  return selectError(children)
}

ErrorText.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BaseError = styled.div`
  color: ${({ theme }) => theme.errorColor};
  font-size: ${({ theme }) => theme.fontsize.small};
  padding-top: ${({ theme }) => theme.marges.small};
`
const NotFoundError = styled(BaseError)`
  padding-top: 0;
  min-height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontsize.medium};
  color: ${({ theme }) => theme.primaryTextColor};
`

export default ErrorText
