import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const InputContainer = props => {
  const { styleType, children } = props
  const selectInputContainer = children => {
    switch (styleType) {
      case 'Profil':
        return <ProfilInputContainer>{children}</ProfilInputContainer>

      case 'Search':
        return <SearchInputContainer>{children}</SearchInputContainer>

      default:
        return <BaseInputContainer>{children}</BaseInputContainer>
    }
  }

  return selectInputContainer(children)
}

InputContainer.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BaseInputContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.mediumsmall};
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ProfilInputContainer = styled(BaseInputContainer)`
  width: 42%;

  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    width: 100%;
  }

  @media ${({ theme }) => theme.device.medium} {
    width: 46%;
  }
`

const SearchInputContainer = styled(BaseInputContainer)`
  margin: 0;
`

export default InputContainer
