import Axios from 'axios'

// Liste des types
export const UPDATE_WEBINAR = 'UPDATE_WEBINAR'
export const UPDATE_WEBINAR_ERROR = 'UPDATE_WEBINAR_ERROR'

export const updateWebinar = (
  titleSend,
  descriptionSend,
  _id,
  enqueueSnackbar,
  t,
  history
) => {
  return async dispatch => {
    try {
      dispatch({
        type: UPDATE_WEBINAR_ERROR,
        payload: null
      })

      const options = {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_EDIT_VIMEO_TOKEN}`
        },
        data: {
          name: titleSend,
          description: descriptionSend
        },
        url: `${process.env.REACT_APP_API_VIMEO_URL}videos/${_id}`
      }

      const { data } = await Axios(options)
      dispatch({
        type: UPDATE_WEBINAR,
        payload: data
      })

      enqueueSnackbar(`${t('updateAlert')}`, {
        variant: 'success'
      })

      history.push(`/webinar/${_id}`)
    } catch (error) {
      const errorDetails = {
        status: error.response.status,
        statusText: error.response.statusText,
        apiMessage: error.response.data
      }

      if (
        error.response.data?.invalid_parameters != null &&
        error.response.data?.invalid_parameters[0].field === 'name'
      ) {
        enqueueSnackbar(t('titleTooLong'), {
          variant: 'error'
        })
      } else if (
        error.response.data?.invalid_parameters != null &&
        error.response.data?.invalid_parameters[0].field === 'description'
      ) {
        enqueueSnackbar(t('descriptionTooLong'), {
          variant: 'error'
        })
      } else {
        enqueueSnackbar(t('updateError'), {
          variant: 'error'
        })
      }

      dispatch({
        type: UPDATE_WEBINAR_ERROR,
        payload: errorDetails
      })
    }
  }
}
