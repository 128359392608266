import {
  GET_ONE_WEBINAR,
  GET_ONE_WEBINAR_ERROR
} from '../actions/getOneWebinar'

const initialState = {
  response: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ONE_WEBINAR:
      return {
        ...state,
        response: action.payload
      }

    case GET_ONE_WEBINAR_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload
      }

    default:
      return state
  }
}
