import Axios from 'axios'

// Liste des types
export const UPDATE_PASSWORD_INFORMATION = 'UPDATE_PASSWORD_INFORMATION'
export const UPDATE_PASSWORD_INFORMATION_ERROR =
  'UPDATE_PASSWORD_INFORMATION_ERROR'

export const updatePassword = (
  lastPassword,
  newPassword,
  _id,
  enqueueSnackbar,
  t,
  history
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_PASSWORD_INFORMATION_ERROR,
        payload: null
      })

      const token = getState().authentication.token
      const options = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          currentPassword: lastPassword,
          newPassword: newPassword
        },
        url: `${process.env.REACT_APP_API_BASE_URL}/auth/updatepassword`
      }
      const { data } = await Axios(options)
      dispatch({
        type: UPDATE_PASSWORD_INFORMATION,
        payload: { data, history }
      })

      enqueueSnackbar(`${t('alertUpdate')}`, {
        variant: 'success'
      })

      history.push('/home')
    } catch (error) {
      const errorDetails = {
        status: error.response.status,
        statusText: error.response.statusText
      }

      if (error.response.data.message === 'tokenExpired') {
        history.push('/signin')
      }
      enqueueSnackbar(t('Unauthorized'), {
        variant: 'error'
      })

      dispatch({
        type: UPDATE_PASSWORD_INFORMATION_ERROR,
        payload: errorDetails
      })
    }
  }
}
