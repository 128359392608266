import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Label = props => {
  const { styleType, children } = props

  const selectLabel = children => {
    switch (styleType) {
      case 'Copyrights':
        return <CopyrightsLabel>{children}</CopyrightsLabel>

      case 'Primary':
        return <PrimaryLabel>{children}</PrimaryLabel>

      case 'PopUp':
        return <PopUpLabel>{children}</PopUpLabel>

      default:
        return <BaseLabel>{children}</BaseLabel>
    }
  }
  return selectLabel(children)
}
Label.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BaseLabel = styled.label`
  font-size: ${({ theme }) => theme.fontsize.medium};
`

const CopyrightsLabel = styled(BaseLabel)`
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: ${({ theme }) => theme.fontsize.mediumsmall};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.margin.xsmall};
`

const PrimaryLabel = styled(BaseLabel)`
  font-size: ${({ theme }) => theme.fontsize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  display: inline-block;
  margin-bottom: ${({ theme }) => theme.margin.xxsmall};
`

const PopUpLabel = styled(BaseLabel)`
  font-size: ${({ theme }) => theme.fontsize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`

export default Label
