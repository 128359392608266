import {
FORGET_PASSWORD,  FORGET_PASSWORD_ERROR,
} from '../actions/forgetPassword'

const initialState = {
  response: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        response: action.payload
      }

    case FORGET_PASSWORD_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload
      }

    default:
      return state
  }
}
