import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import fr from './fr'
import en from './en'

const resources = {
  fr,
  en
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-US',
    resources,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
