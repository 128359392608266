export default {
  translation: {
    // Navlinks
    navlien1: 'Webinars',
    navlien2: 'Profil',
    navlien3: 'Paramètres',
    navlien4: 'Se déconnecter',

    //Signup and Signin Form
    signUpTitle: 'Accéder à un contenu de qualité',
    signInTitle: 'Heureux de vous revoir',
    signUpSubTitle: 'Entrez vos informations pour commencer',
    signInSubTitle: 'Apprenez avec nous !',
    lastNameLabel: 'Nom',
    lastNamePlaceholder: 'Martin',
    firstNameLabel: 'Prénom',
    firstNamePlaceholder: 'François',
    emailLabel: 'E-mail',
    emailPlaceholder: 'françois@exemple.com',
    passwordLabel: 'Mot de passe',
    signInButton: 'Se connecter',
    signUpButton: 'Créer un compte',
    linkToSignIn: 'Déja inscrit ?',
    linkToSignInBold: 'Connectez-vous !',
    linkToSignUp: "Vous n'avez pas encore de compte ?",
    linkToSignUpBold: 'Créez-en un !',
    submitButton: 'Envoyer',
    forgetPassword: 'Mot de passe oublié ?',
    loginAlert: 'Heureux de vous revoir ',
    registerAlert: 'Compte créé',
    disconnectAlert: 'Vous avez été deconnecté',
    apiAuthNotOnline: "L'API d'authentification n'est pas connectée",

    //Error message
    emailError: 'Email incorrect',
    registerError: 'Erreur lors de la création du compte',
    mailAlreadyUsed: 'Un compte est déja associé à cet email',
    401: 'Email ou mot de passe incorrect',
    textError: 'Veuillez saisir uniquement des lettres',
    titleError: 'Veuillez ne pas saisir de caractères spéciaux',
    fieldRequired: 'Champ requis',
    titleTooLong: 'Veuillez saisir un titre moins long',
    descriptionTooLong: 'Veuillez saisir une description moins longue',
    tokenExpired: 'Votre session à expirée',
    unauthorizedRole:
      "Votre rôle actuel ne vous permet pas d'acceder à ce contenu, veuillez vous reconnecter",

    //Home Form
    webinarDescription:
      "C'est ici que vous retrouverez les replays des webinars d'Izencia",
    placeHolderWebinar: 'Rechercher un webinar',
    createWebinarButton: 'Créer un webinar',
    noResult: 'Aucun webinar ne correspond à votre recherche',
    webinars: 'Webinars',
    seeMore: 'Voir plus',

    //Webinar Form
    titleLabel: 'Titre',
    titlePlaceholder: 'Webinar exemple...',
    descriptionLabel: 'Description',
    descriptionPlaceholder: 'Description du webinar...',
    editLabel: "Edition d'un webinar",
    editButton: 'Editer',
    deleteButton: 'Supprimer',
    updateButton: 'Mettre à jour',
    GoToPassword: 'Changer votre mot de passe',
    ErrorAreaEmpty: 'Veuillez remplir tous les champs',
    webinarEdit: 'Vous êtes sur le point de modifier un webinar',
    deleteConfirm: 'Etes-vous sûr de vouloir supprimer ce webinar ?',
    deleteAlert: 'Webinar supprimé',
    updateAlert: 'Webinar mis à jour',
    updateError: 'Veuillez saisir un titre',
    incorrectInput:
      'Un ou plusieurs champ(s) ne respecte(nt) pas les conditions',
    noWebinarFound: "Le webinar n'existe pas",
    abortButton: 'Fermer',
    deleteWebinar: "Suppression d'un webinar",
    UnableToGetUsers: 'Impossible de récupérer les utilisateurs',
    Unauthorized: 'Une erreur est survenue',

    //Profil Form
    profilLabel: 'Votre Profil',
    profilDesc: 'Vous pouvez modifier les informations non grisées',
    roleLabel: 'Role',
    newPassword: 'Nouveau mot de passe',
    lastPassword: 'Ancien mot de passe',
    notCorrectLastPassword: "L'ancien mot de passe est incorrect",
    notCorrectNewPassword:
      'Le nouveau mot de passe ne remplit pas les critères',
    publisher: 'Editeur',
    admin: 'Admin',
    user: 'Utilisateur',
    alertUpdate: 'Profil mis à jour',

    //Password match:
    passwordRule1: '8 à 32 caractères',
    passwordRule2: 'Une majuscule',
    passwordRule3: 'Une minuscule',
    passwordRule4: 'Un nombre',
    passwordRule5: 'Un caractère spécial',

    //AdminInterfaceForm
    adminLabel: 'Gestion des utilisateurs',
    adminDesc: 'Vous pouvez consulter les differents utilisateurs',
    placeHolderSearch: 'Rechercher un utilisateur',

    //UserRoleForm
    roleTitle: "Gestion d'un utilisateur",
    roleDesc: "Vous pouvez modifier le role de l'utilisateur",
    selectUser: 'Selectionnez un utilisateur',
    selectRole: 'Selectionnez un role',
    roleNotConform: 'Veuillez selectionner un role',
    idNotConform: 'Veuillez selectionner un utilisateur',
    fillInArea: 'Veuillez remplir tous les champs',
    alertRoleChanged: 'Utilisateur mis à jour',
    noUserFound: "L'utilisateur n'existe pas",

    //ResetPasswordForm
    resetLabel: 'Mise à jour du mot de passe',
    resetDesc: 'Veuillez saisir un nouveau mot de passe',
    ErrorPasswordReset: 'Une erreur est survenue',
    invalidToken: 'Accès non autorisé',
    resetAlert: 'Votre mot de passe à été mis à jour',

    //ForgetPasswordForm
    forgetLabel: 'Réinitialisation du mot de passe',
    mailLabel: 'Veuillez saisir votre adresse mail',
    forgetAlert: 'Vous avez reçu un mail pour réinitialiser votre mot de passe',
    noUserEmail: "Aucun utilisateur n'est relié à cet email",
    noEmailSent: "L'email n'a pas pu être envoyé",

    //NotFound
    errorLabel: 'Il semblerait que vous soyez perdu',
    returnHome: 'Vous pouvez retourner sur la page',
    returnHomeBold: 'Webinar',
    returnSignIn: 'Vous pouvez retrouner sur la page de',
    returnSignInBold: 'Connexion',
    error404: '404',
    errorTokenVerify: 'Une erreur est survenue',

    //Table
    nextButton: 'Suivant',
    previousButton: 'Précédent',

    //Policy
    policyTitle: 'Politique de confidentialité',
    preference: 'Vos préférences',
    textTitle1: 'Introduction',
    text1:
      'Dans le cadre de son activité, la société izencia, dont le siège social est situé au 5 avenue de Verdun à Ivry sur Seine (94200), est amenée à collecter et à traiter des informations dont certaines sont qualifiées de « données personnelles ». Izencia attache une grande importance au respect de la vie privée, et n’utilise que des données de manière responsable et confidentielle et dans une finalité précise.',
    textTitle2: 'Données personnelles',
    text2a:
      'Sur le site web (NOM DE DOMAINE A DEFINIR), il y a deux types de données susceptibles d’être recueillies :',
    text2b: 'Les données transmises directement',
    text2c:
      "Ces données sont celles que vous nous transmettez directement, via le formualire d'inscription. Ceci induit votre nom, prénom et mail.",
    text2d: 'Les données collectées automatiquement',
    text2e:
      'Lors de vos visites, une fois votre consentement donné, nous pouvons recueillir des informations de type « web analytics » relatives à votre navigation, la durée de votre consultation, votre adresse IP, votre type et version de navigateur. La technologie utilisée est le cookie.',
    textTitle3: 'Utilisation des données',
    text3:
      'Les données que vous nous transmettez directement sont utilisées dans le but de vous re-contacter et/ou dans le cadre de la demande que vous nous faites. Les données « web analytics » sont collectées de forme anonyme (en enregistrant des adresses IP anonymes) par Google Analytics, et nous permettent de mesurer l’audience de notre site web, les consultations et les éventuelles erreurs afin d’améliorer constamment l’expérience des utilisateurs. Ces données sont utilisées par izencia, responsable du traitement des données, et ne seront jamais cédées à un tiers ni utilisées à d’autres fins que celles détaillées ci-dessus.',
    textTitle4: 'Base légale',
    text4:
      'Les données personnelles ne sont collectées qu’après consentement obligatoire de l’utilisateur. Ce consentement est valablement recueilli (boutons et cases à cocher), libre, clair et sans équivoque.',
    textTitle5: 'Durée de conservation',
    text5:
      'Les données seront sauvegardées durant une durée maximale de 3 ans.',
    textTitle6: 'Cookies',
    text6a: 'Voici la liste des cookies utilisées et leur objectif :',
    text6b: 'Durée de vie maximale des cookies de 13 mois',
    text6c: 'Cookies Google Analytics (liste exhaustive) : Web analytics',
    text6d: 'Vimeo : Permet d’accéder aux vidéos de notre site',
    textTitle7: 'Vos droits concernant les données personnelles',
    text7:
      'Vous avez le droit de consultation, demande de modification ou d’effacement sur l’ensemble de vos données personnelles. Vous pouvez également retirer votre consentement au traitement de vos données en cliquant sur ce lien : ',
    link7: 'Changer vos préférences pour la gestion des cookies',
    textTitle8: 'Contact délégué à la protection des données',
    text8: 'Contacter – DPO – dpo@izencia.com'
  }
}
