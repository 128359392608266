import React, { useEffect } from 'react'
import ProfilForm from '../../components/ProfilForm'

//components
import ScreenContainer from '../../components/common/ScreenContainer'
import TitleContainer from '../../components/common/TitleContainer'
import Title from '../../components/common/Titles/Title'
import SubTitle from '../../components/common/Titles/SubTitle'

//redux
import * as Actions from '../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'

//translation
import { useTranslation } from 'react-i18next'

//cliploader
import PageLoader from '../../components/common/PageLoader'

const Profil = props => {
  const { token } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  //we can not get the user information with token because
  //it will not be updated unless a disconnection
  const user = useSelector(state => state.getMe)
  let id = token?.id

  //load user information
  useEffect(() => {
    //check token
    if (id) {
      dispatch(Actions.getMe(id))
    }
  }, [id, dispatch])

  return (
    <ScreenContainer token={token}>
      <TitleContainer styleType='Page'>
        <Title>{t('profilLabel')}</Title>
        <SubTitle styleType='Primary'>{t('profilDesc')}</SubTitle>
      </TitleContainer>
      {user.User !== null ? (
        <ProfilForm user={user} token={token}></ProfilForm>
      ) : (
        <PageLoader />
      )}
    </ScreenContainer>
  )
}

export default Profil
