import { UPDATE_ROLE, UPDATE_ROLE_ERROR } from '../actions/updateRole'

const initialState = {
  response: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ROLE:
      return {
        ...state,
        response: action.payload.message
      }

    case UPDATE_ROLE_ERROR:
      return {
        ...state,
        response: null,
        error: action.payload
      }

    default:
      return state
  }
}
