import Axios from 'axios'

// Liste des types
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR'

// Change le thème
export const register = (
  userFirstName,
  userLastName,
  userEmail,
  userPassword,
  enqueueSnackbar,
  t,
  history
) => {
  return async dispatch => {
    try {
      dispatch({
        type: REGISTER_USER_ERROR,
        payload: null
      })

      const options = {
        method: 'POST',
        data: {
          firstname: userFirstName,
          lastname: userLastName,
          email: userEmail,
          password: userPassword
        },
        url: `${process.env.REACT_APP_API_BASE_URL}/auth/register`
      }

      const { data } = await Axios(options)
      dispatch({
        type: REGISTER_USER,
        payload: data
      })

      enqueueSnackbar(`${t('registerAlert')}`, {
        variant: 'success'
      })

      history.push('/signin')
    } catch (error) {
      const errorDetails = {
        status: error.response?.status,
        statusText: error.response?.statusText
      }

      if (error.response?.statusText == null) {
        enqueueSnackbar(t('apiAuthNotOnline'), {
          variant: 'error'
        })
      } else {
        enqueueSnackbar(t('Unauthorized'), {
          variant: 'error'
        })
      }
      dispatch({
        type: REGISTER_USER_ERROR,
        payload: errorDetails
      })
    }
  }
}
