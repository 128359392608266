import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

//components
import Title from '../common/Titles/Title'
import SubTitle from '../common/Titles/SubTitle'
import Button from '../common/Button'
import TitleContainer from '../common/TitleContainer'

//redux
import { useHistory } from 'react-router-dom'

//translation
import { useTranslation } from 'react-i18next'

//videoPlayer
//import Vimeo from '@vimeo/player'

const WebinarForm = props => {
  const { webinarResponse, webinarId, token } = props
  const { t } = useTranslation()
  const history = useHistory()

  let right = false

  if (token?.role === 'admin' || token?.role === 'publisher') {
    right = true
  }

  const goToWebinar = webinarId => {
    history.push(`/webinar/${webinarId}/edit`)
  }

  /*var player1 = document.getElementById('player')
  var vimeoplayer = new Vimeo(player1)
  vimeoplayer.ready().then(function () {
    vimeoplayer.play()
  })*/

  return (
    <WebinarContainer>
      <Video>
        <Player
          id='player'
          src={`${process.env.REACT_APP_VIMEO_URL}`.concat('', webinarId)}
          height='100%'
          width='100%'
          allowFullScreen
        ></Player>
      </Video>

      <TitleContainer>
        <Title styleType='Webinar'>{webinarResponse.response.name}</Title>
        <SubTitle styleType='Screen'>
          {webinarResponse.response.description}
        </SubTitle>
      </TitleContainer>
      {right ? (
        <Button styleType='Primary' onClick={() => goToWebinar(webinarId)}>
          {t('editButton')}
        </Button>
      ) : null}
    </WebinarContainer>
  )
}

WebinarForm.propTypes = {
  webinarResponse: PropTypes.object,
  webinarId: PropTypes.string
}

const WebinarContainer = styled.div`
  width: 100%;
  height: 100%;

  @media ${({ theme }) => theme.device.xxlarge} {
    padding-right: 20%;
    max-width: 75.75rem;
  }

  @media ${({ theme }) => theme.device.xlarge} {
    width: 80%;
  }
`

const Video = styled.div`
  position: relative;
  padding-top: 56.25%;
  margin-bottom: ${({ theme }) => theme.margin.small};

  /*
  @media ${({ theme }) => theme.device.xlarge} {
    width: 60%;
    height: 380px;
  }
  @media ${({ theme }) => theme.device.large} {
    height: 500px;
    width: 800px;
  }
  @media ${({ theme }) => theme.device.medium} {
    height: 500px;
    width: 750px;
  }
  @media ${({ theme }) => theme.device.small} {
    height: 450px;
    width: 600px;
  }
  @media ${({ theme }) => theme.device.xsmall} {
    height: 250px;
    width: 100%;
  }*/
`

const Player = styled.iframe`
  position: absolute;
  top: 0;
`

export default WebinarForm
