import React from 'react'
import styled from 'styled-components'

//components
import Button from '../Button'
import Img from '../Img'
import Span from '../Span'

//redux
import * as Actions from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//images
import IzenciaLogo from '../../../assets/images/izenciaLogo300px71px.png'

//translation
import { useTranslation } from 'react-i18next'

import { useSnackbar } from 'notistack'

const HamburgerNav = props => {
  const { open, setOpen, token } = props
  const { t } = useTranslation()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  //Action
  const disconnectUser = e => {
    e.preventDefault()
    enqueueSnackbar(`${t('disconnectAlert')}`, {
      variant: 'default'
    })

    setOpen(!open)

    dispatch(Actions.logout(history))
  }

  const goToPage = path => {
    //close the hamburger menu
    setOpen(!open)
    history.push(path)
  }

  //action
  const backToHome = () => {
    setOpen(false)
    history.push('/home')
  }

  return (
    <NavBarContainer open={open}>
      <Img
        open={open}
        styleType='Logo'
        src={IzenciaLogo}
        onClick={() => backToHome()}
      ></Img>

      {token ? (
        <>
          <Ul open={open}>
            <LiDiv>
              <Img
                open={open}
                styleType='Header'
                src={IzenciaLogo}
                onClick={() => backToHome()}
              ></Img>
            </LiDiv>
            <Li onClick={e => goToPage('/home')}>
              <Span styleType='Header'>{t('navlien1')}</Span>
            </Li>
            <Li onClick={e => goToPage('/profil')}>
              <Span styleType='Header'>{t('navlien2')}</Span>
            </Li>
            {token?.role === 'admin' ? (
              <Li onClick={e => goToPage('/admin')}>
                <Span styleType='Header'>{t('navlien3')}</Span>
              </Li>
            ) : null}

            <LiDiv>
              <Button
                open={open}
                styleType='HeaderPhone'
                onClick={e => disconnectUser(e)}
              >
                {t('navlien4')}
              </Button>
            </LiDiv>
          </Ul>

          <Button styleType='Header' onClick={e => disconnectUser(e)}>
            {t('navlien4')}
          </Button>
        </>
      ) : null}
    </NavBarContainer>
  )
}

const NavBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${({ theme }) => theme.device.xxlarge} {
    max-width: 75.75rem;
  }
`

const Ul = styled.ul`
  list-style: none;
  display: flex;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    flex-flow: column nowrap;
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    transition: transform 0.3s ease-in-out;

    //delete shadow with BlurContainer
    box-shadow: 1px 1px ${({ theme }) => theme.noColor};
  }

  @media ${({ theme }) => theme.device.xsmall} {
    width: 100%;
  }
`

const Li = styled.li`
  margin: 0 ${({ theme }) => theme.margin.medium};
  font-size: ${({ theme }) => theme.fontsize.medium};
  font-weight: bold;
  display: inline-block;
  position: relative;
  padding: ${({ theme }) => theme.padding.mediumsmall} 0;
  cursor: pointer;

  //underline
  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: ${({ theme }) => theme.accentColor};
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
    @media ${({ theme }) => theme.device.small},
      ${({ theme }) => theme.device.xsmall} {
      left: 0;
    }
  }
  &:hover: after {
    width: 40%;
    left: 30%;
    @media ${({ theme }) => theme.device.small},
      ${({ theme }) => theme.device.xsmall} {
      left: 0;
      width: 30%;
    }
  }

  @media ${({ theme }) => theme.device.medium},
    ${({ theme }) => theme.device.large} {
    margin: 0 ${({ theme }) => theme.margin.small};
  }

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.margin.xlarge};
  }
`

const LiDiv = styled(Li)`
  display: none;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: flex;
    justify-content: center;
    margin: 0;
    height: 4rem;
    padding: 0;

    // no underline
    &:after {
    }
    &:hover: after {
      width: 0;
    }
  }
`

export default HamburgerNav
