import React from 'react'
import styled from 'styled-components'

//components
import Text from '../Text'
import CardTitle from '../CardTitle'

//translation
import { useTranslation } from 'react-i18next'

const Modal = props => {
  const { children, setOpenModal, openModal } = props
  const { t } = useTranslation()

  const closeModal = e => {
    if (e.target.id === 'Container') {
      setOpenModal(false)
    }
  }

  return (
    <ModalContainer
      openModal={openModal}
      onClick={e => closeModal(e)}
      id='Container'
    >
      <ModalContent>
        <ModalDiv>
          <CardTitle styleType='Modal'>{t('deleteWebinar')}</CardTitle>
        </ModalDiv>
        <ModalDiv>
          <Text styleType='Modal'>{t('deleteConfirm')}</Text>
        </ModalDiv>
        <ModalDiv>
          <ModalButton>{children}</ModalButton>
        </ModalDiv>
      </ModalContent>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: ${({ openModal }) => (openModal ? 'all' : 'none')};
  background-color: ${({ theme }) => theme.quaternaryBackgroundColor}70;
  opacity: ${({ openModal }) => (openModal ? '1' : '0')};
  transition: opacity 0.2s;
`

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.noColor};
  border-radius: ${({ theme }) => theme.borderRadius.xsmall};
  min-width: 35rem;
  border: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    min-width: 0;
  }
  @media ${({ theme }) => theme.device.xsmall}, {
    margin: 0 15px;
  }
`

const ModalDiv = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.padding.mediumsmall};
  border-bottom: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;

  :last-child {
    border-bottom: none;
  }
`

const ModalButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 15rem;

  @media ${({ theme }) => theme.device.xsmall} {
    min-width: 10rem;
  }
`

export default Modal
