import {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
} from '../actions/authentication'

const initialState = {
  token: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        token: action.payload.token,
      }

    case LOGIN_USER_ERROR:
      return {
        ...state,
        token: null,
        error: action.payload
      }

    case LOGOUT_USER:
      return {
        ...state,
        token: null,
        error: null
      }

    default:
      return state
  }
}
