import React, { useState } from 'react'
import styled from 'styled-components'
import HamburgerNav from '../common/HamburgerNav'

const Header = props => {
  const { token } = props
  const [open, setOpen] = useState(false)

  return (
    <FixedContainer>
      <HeaderContainer open={open}>
        <HamburgerNav open={open} setOpen={setOpen} token={token} />
        {token !== null ? (
          <BurgerMenu open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
          </BurgerMenu>
        ) : null}
      </HeaderContainer>
      {open ? (
        <BlurContainer onClick={() => setOpen(!open)}></BlurContainer>
      ) : null}
    </FixedContainer>
  )
}

const FixedContainer = styled.div`
  min-height: ${({ theme }) => theme.headerHeight};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    min-height: ${({ theme }) => theme.phoneHeaderHeight};
  }

  @media ${({ theme }) => theme.device.xxlarge} {
    display: flex;
    align-items: center;
    justify-content: center;
    maw-width: 100%;
  }
`

const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  height: ${({ theme }) => theme.headerHeight};
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  margin: 0 ${({ theme }) => theme.margin.container};
  position: fixed;
  z-index: 3;
  top: 0;

  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    height: ${({ theme }) => theme.phoneHeaderHeight};
    margin-left: ${({ theme }) => theme.margin.medium};
    margin-right: ${({ theme }) => theme.margin.medium};
    right: 0;
    width: ${({ open }) => (open ? 'fit-content' : '-webkit-fill-available;')};
  }

  @media ${({ theme }) => theme.device.medium} {
    margin-left: ${({ theme }) => theme.margin.large};
    margin-right: ${({ theme }) => theme.margin.large};
  }

  @media ${({ theme }) => theme.device.large} {
    margin-left: ${({ theme }) => theme.margin.xlarge};
    margin-right: ${({ theme }) => theme.margin.xlarge};
  }

  @media ${({ theme }) => theme.device.xxlarge}, {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

const BurgerMenu = styled.div`
  
    width: 1.5rem;
    height: 1.5rem;

    z-index: 5;
    display: none;

    @media ${({ theme }) => theme.device.small},
      ${({ theme }) => theme.device.xsmall} {
      display: flex;
      justify-content: space-around;
    flex-flow: column wrap;
      }

    div {
      width: 1.5rem;
      height: 0.25rem;
      background-color: ${({ theme }) => theme.secondaryBackgroundColor};
      border-radius: 10px;
      transform-origin: 1px;
      transition: all 0.3s linear;

      &:nth-child(1) {
        transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      }

      &:nth-child(2) {
        transform: ${({ open }) =>
          open ? 'translateX(100%)' : 'translateX(0)'};
        opacity: ${({ open }) => (open ? 0 : 1)};
      }

      &:nth-child(3) {
        transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      }
    }
  }
`
const BlurContainer = styled.div`
  display: none;
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    background-color: ${({ theme }) => theme.quaternaryBackgroundColor}50;
    backdrop-filter: blur(4px);
  }
`
export default Header
