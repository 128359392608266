import { combineReducers } from 'redux'
import authentication from './authentication'
import register from './register'
import getWebinar from './getWebinar'
import getOneWebinar from './getOneWebinar'
import deleteWebinar from './deleteWebinar'
import updateWebinar from './updateWebinar'
import updateUser from './updateUser'
import getAllUser from './getAllUser'
import updateRole from './updateRole'
import resetPassword from './resetPassword'
import forgetPassword from './forgetPassword'
import getOneUser from './getOneUser'
import getMe from './getMe'
import updatePassword from './updatePassword'

export default combineReducers({
  authentication,
  register,
  getWebinar,
  getOneWebinar,
  deleteWebinar,
  updateWebinar,
  updateUser,
  updatePassword,
  getAllUser,
  updateRole,
  resetPassword,
  forgetPassword,
  getOneUser,
  getMe
})
