import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

//components
import Img from '../Img'
import Label from '../Label'

//Images
import Check from '../../../assets/images/check.png'
import Error from '../../../assets/images/error.png'

//translation
import { useTranslation } from 'react-i18next'

//Regex
import {
  UPPER_REGEX,
  LOWER_REGEX,
  NUMBER_REGEX,
  SPECIAL_REGEX
} from '../../../config/regex'

const PopUp = props => {
  const { password, direction } = props
  let length = false
  let upper = false
  let lower = false
  let number = false
  let special = false

  //const { length, maj, min, number, special, direction } = props
  const { t } = useTranslation()

  //check password rules
  //length
  if (password.length >= 8 && password.length < 32) {
    length = true
  }

  //upper
  if (UPPER_REGEX.test(password)) {
    upper = true
  }

  //lower
  if (LOWER_REGEX.test(password)) {
    lower = true
  }

  //number
  if (NUMBER_REGEX.test(password)) {
    number = true
  }

  //special
  if (SPECIAL_REGEX.test(password)) {
    special = true
  }

  return (
    <PopUpContainer direction={direction}>
      {direction === 'left' || direction === 'top' ? (
        <Triangle direction={direction} />
      ) : null}

      <PopUpContent>
        <InformationBox>
          {length ? (
            <Img styleType='PopUp' src={Check}></Img>
          ) : (
            <Img styleType='PopUp' src={Error}></Img>
          )}

          <Label styleType='PopUp'>{t('passwordRule1')}</Label>
        </InformationBox>
        <InformationBox>
          {upper ? (
            <Img styleType='PopUp' src={Check}></Img>
          ) : (
            <Img styleType='PopUp' src={Error}></Img>
          )}
          <Label styleType='PopUp'>{t('passwordRule2')}</Label>
        </InformationBox>
        <InformationBox>
          {lower ? (
            <Img styleType='PopUp' src={Check}></Img>
          ) : (
            <Img styleType='PopUp' src={Error}></Img>
          )}
          <Label styleType='PopUp'>{t('passwordRule3')}</Label>
        </InformationBox>
        <InformationBox>
          {number ? (
            <Img styleType='PopUp' src={Check}></Img>
          ) : (
            <Img styleType='PopUp' src={Error}></Img>
          )}
          <Label styleType='PopUp'>{t('passwordRule4')}</Label>
        </InformationBox>
        <InformationBox>
          {special ? (
            <Img styleType='PopUp' src={Check}></Img>
          ) : (
            <Img styleType='PopUp' src={Error}></Img>
          )}
          <Label styleType='PopUp'>{t('passwordRule5')}</Label>
        </InformationBox>
      </PopUpContent>
      {direction === 'right' || direction === 'bottom' ? (
        <Triangle direction={direction} />
      ) : null}
    </PopUpContainer>
  )
}

PopUp.propTypes = {
  password: PropTypes.string,
  direction: PropTypes.string
}

const PopUpContainer = styled.div`
  position: absolute;
  top: ${({ direction }) => (direction === 'top' ? '100%' : 'auto')};
  bottom: ${({ direction }) => (direction === 'bottom' ? '100%' : 'auto')};
  right: ${({ direction }) => (direction === 'right' ? '100%' : 'auto')};
  left: ${({ direction }) => (direction === 'right' ? '100%' : 'auto')};
  left: ${({ direction }) =>
    direction === 'left'
      ? '100%'
      : direction === 'top' || direction === 'bottom'
      ? '10%'
      : 'auto'};
  width: fit-content;
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'top' || direction === 'bottom' ? 'column' : 'row'};
  align-items: center;
  z-index: 1;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    //direction is bottom
    top: auto;
    right: auto;
    left: 10%;
    bottom: 100%;
    flex-direction: ${({ direction }) =>
      direction === 'top' || direction === 'left'
        ? 'column-reverse'
        : 'column'};
  }
`

const Triangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-top: ${({ direction }) =>
    direction === 'bottom'
      ? '10px solid #006391'
      : direction === 'top'
      ? 'none'
      : '10px solid transparent'};
  border-right: ${({ direction }) =>
    direction === 'left'
      ? '10px solid #006391'
      : direction === 'right'
      ? 'none'
      : '10px solid transparent'};
  border-bottom: ${({ direction }) =>
    direction === 'top'
      ? '10px solid #006391'
      : direction === 'bottom'
      ? 'none'
      : '10px solid transparent'};
  border-left: ${({ direction }) =>
    direction === 'right'
      ? '10px solid #006391'
      : direction === 'left'
      ? 'none'
      : '10px solid transparent'};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    //direction is bottom
    border-top: 10px solid #006391;
    border-right: 10px solid transparent;
    border-bottom: none;
    border-left: 10px solid transparent;
  }
`

const PopUpContent = styled.div`
  background-color: ${({ theme }) => theme.noColor};
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: ${({ theme }) => theme.padding.small};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: 1px solid ${({ theme }) => theme.secondaryBackgroundColor};
`

const InformationBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xxsmall};
`
export default PopUp
