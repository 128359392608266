import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PasswordContainer = props => {
  const { styleType, children } = props

  const selectPasswordContainer = children => {
    switch (styleType) {
      case 'Profil':
        return <ProfilPasswordContainer>{children}</ProfilPasswordContainer>

      default:
        return <BasePasswordContainer>{children}</BasePasswordContainer>
    }
  }
  return selectPasswordContainer(children)
}

PasswordContainer.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BasePasswordContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor};
  border-radius: 10px;
`

const ProfilPasswordContainer = styled(BasePasswordContainer)`
  background-color: ${({ theme }) => theme.noColor};
  border: none;
`

export default PasswordContainer
