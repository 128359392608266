import Axios from 'axios'

// Liste des types
export const GET_WEBINAR = 'GET_WEBINAR'
export const GET_WEBINAR_ERROR = 'GET_WEBINAR_ERROR'

export const getWebinar = (query, pageNumber, perPage) => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_WEBINAR_ERROR,
        payload: null
      })

      //show all webinars if query is empty
      let queryUrl = ''
      if (query === '') {
        queryUrl = `?page=${pageNumber}&per_page=${process.env.REACT_APP_WEBINAR_PER_PAGE}`
      } else {
        queryUrl = `?query="${query}`
      }
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VIMEO_TOKEN}`
        },
        data: {},
        url: `${process.env.REACT_APP_API_VIMEO_URL}${process.env.REACT_APP_CLIENT_ID}projects/${process.env.REACT_APP_WEBINAR_FOLDER_ID}videos${queryUrl}`
      }

      const { data } = await Axios(options)

      dispatch({
        type: GET_WEBINAR,
        payload: data
      })
    } catch (error) {
      const errorDetails = {
        status: error?.response?.status,
        statusText: error?.response?.statusText,
        apiMessage: error?.response?.data?.message
      }

      dispatch({
        type: GET_WEBINAR_ERROR,
        payload: errorDetails
      })
    }
  }
}
