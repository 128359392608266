import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { lightTheme, reset } from './config/theme'

import { Provider } from 'react-redux'
import * as OpenSans from './assets/fonts/OpenSans/open-sans-v20-latin-regular.woff2'
import * as OpenSansSemiBold from './assets/fonts/OpenSans/open-sans-v20-latin-600.woff2'
import * as OpenSansBold from './assets/fonts/OpenSans/open-sans-v20-latin-700.woff2'
import Routes from './config/routes/router'
import { store } from './redux'

import { SnackbarProvider } from 'notistack'
import Slide from '@material-ui/core/Slide'

const GlobalStyle = createGlobalStyle`
${reset}

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans'), local('Open Sans-Regular'), url(${OpenSans}) format('woff2');
    
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    
    src: local('Open Sans 2'), local('Open Sans-Bold'), url(${OpenSansSemiBold}) format('woff2');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    
    src: local('Open Sans 2'), local('Open Sans-Bold'), url(${OpenSansBold}) format('woff2');
  }

  * {
    font-family: 'Open Sans';
    padding: 0px;
    margin: 0px;
  }

  body {
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    color: ${({ theme }) => theme.primaryTextColor};
  }
`

/*const chokidar = require('chokidar')

// One-liner for current directory
chokidar.watch('.').on('all', (event, path) => {
  console.log(event, path)
})*/

const App = styles => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          TransitionComponent={Slide}
          autoHideDuration={3000}
          disableWindowBlurListener={true}
          preventDuplicate={true}
        >
          <GlobalStyle />
          <Routes></Routes>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
