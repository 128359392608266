import { commonTheme } from '../common'

export const lightTheme = {
  ...commonTheme,
  //background
  primaryBackgroundColor: commonTheme.colors.lightGray,
  secondaryBackgroundColor: commonTheme.colors.blue,
  tertiaryBackgroundColor: commonTheme.colors.purple,
  quaternaryBackgroundColor: commonTheme.colors.darkGray,

  //text
  primaryTextColor: commonTheme.colors.purple,
  secondaryTextColor: commonTheme.colors.darkGray,
  tertiaryTextColor: commonTheme.colors.lightGray,
  quaternaryTextColor: commonTheme.colors.blue,

  //special
  accentColor: commonTheme.colors.green,
  noColor: commonTheme.colors.white,
  errorColor: commonTheme.colors.red,

  //old
  quinaryBackgroundColor: commonTheme.colors.middleBlue
}
