import React from 'react'
import styled from 'styled-components'

//components
import ScreenContainer from '../../components/common/ScreenContainer'
import Title from '../../components/common/Titles/Title'
import Text from '../../components/common/Text'
import TitleContainer from '../../components/common/TitleContainer'
import MyLink from '../../components/common/MyLink'
import SubTitle from '../../components/common/Titles/SubTitle'

//translation
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Policy = props => {
  const token = useSelector(state => state.authentication?.token)
  const { t } = useTranslation()
  return (
    <ScreenContainer token={token}>
      <TitleContainer styleType='Page'>
        <Title>{t('policyTitle')}</Title>
      </TitleContainer>
      <SubTitle styleType='Secondary'>{t('textTitle1')}</SubTitle>
      <Text styleType='Policy'>{t('text1')}</Text>

      <SubTitle styleType='Secondary'>{t('textTitle2')}</SubTitle>
      <Text styleType='Policy'>{t('text2a')}</Text>
      <Text styleType='Policy' type='bold'>
        {t('text2b')}
      </Text>
      <Text styleType='Policy'>{t('text2c')}</Text>
      <Text styleType='Policy' type='bold'>
        {t('text2d')}
      </Text>
      <Text styleType='Policy'>{t('text2e')}</Text>

      <SubTitle styleType='Secondary'>{t('textTitle3')}</SubTitle>
      <Text styleType='Policy'>{t('text3')}</Text>

      <SubTitle styleType='Secondary'>{t('textTitle4')}</SubTitle>
      <Text styleType='Policy'>{t('text4')}</Text>

      <SubTitle styleType='Secondary'>{t('textTitle5')}</SubTitle>
      <Text styleType='Policy'>{t('text5')}</Text>

      <SubTitle styleType='Secondary'>{t('textTitle6')}</SubTitle>
      <Text styleType='Policy'>
        {t('text6a')}
        <br></br>
        {t('text6b')}
      </Text>
      <List>
        <Flea>{t('text6c')}</Flea>
        <Flea>{t('text6d')}</Flea>
      </List>

      <SubTitle styleType='Secondary'>{t('textTitle7')}</SubTitle>
      <Text styleType='Policy'>
        {t('text7')}
        <MyLink styleType='Consent' to='#' id='open_preferences_center'>
          {t('link7')}
        </MyLink>
      </Text>

      <SubTitle styleType='Secondary'>{t('textTitle8')}</SubTitle>
      <MyLink styleType='Mail' to='#' mail='mailto:dpo@izencia.com'>
        {t('text8')}
      </MyLink>
    </ScreenContainer>
  )
}

const List = styled.ul`
  margin-bottom: ${({ theme }) => theme.margin.small};
  margin-left: ${({ theme }) => theme.margin.medium};
`

const Flea = styled.li`
  list-style-type: disc;
  padding-bottom: ${({ theme }) => theme.padding.xxsmall};
`
export default Policy
