import React, { useEffect } from 'react'

//components
import WebinarForm from '../../components/WebinarForm'
import ScreenContainer from '../../components/common/ScreenContainer'
import ErrorText from '../../components/common/ErrorText'

//translation
import { useTranslation } from 'react-i18next'

//redux
import * as Actions from '../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

//cliploader
import PageLoader from '../../components/common/PageLoader'

const Webinar = props => {
  const { token } = props
  const webinarResponse = useSelector(state => state.getOneWebinar)

  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()

  //split the pathname
  const splitpath = location.pathname.split('/')
  //get the id in the pathname
  const webinarId = splitpath[2]

  //get the webinar
  useEffect(() => {
    dispatch(Actions.getOneWebinar(webinarId))
  }, [webinarId, dispatch])

  return (
    <ScreenContainer token={token}>
      {webinarResponse.response != null ? (
        <WebinarForm
          webinarResponse={webinarResponse}
          webinarId={webinarId}
          token={token}
        ></WebinarForm>
      ) : webinarResponse.error != null ? (
        <ErrorText styleType={'NotFound'}>{t('noWebinarFound')}</ErrorText>
      ) : (
        <PageLoader></PageLoader>
      )}
    </ScreenContainer>
  )
}

export default Webinar
