import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

//components
import Label from '../common/Label'
import Input from '../common/Input'
import Button from '../common/Button'
import Img from '../common/Img'
import ErrorText from '../common/ErrorText'
import InputContainer from '../common/InputContainer'
import PasswordContainer from '../common/PasswordContainer'
import PopUp from '../common/PopUp'

//redux
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../redux/actions'

//translation
import { useTranslation } from 'react-i18next'

//snackbar
import { useSnackbar } from 'notistack'

//images
import Visibility from '../../assets/images/visibility40px40px.png'
import NoVisibility from '../../assets/images/noVisibility40px40px.png'

//regex
import { PASSWORD_REGEX_3, nameRegex, emailRegex } from '../../config/regex'

const SignUpForm = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  //check if already connected
  const userToken = useSelector(state => state.authentication?.token)

  useEffect(() => {
    if (userToken) {
      history.push('/home')
    }
  }, [userToken, history])

  const { enqueueSnackbar } = useSnackbar()

  const [firstname, setFirstname] = useState({
    value: '',
    isError: false,
    isErrorMessage: ''
  })

  const [lastname, setLastname] = useState({
    value: '',
    isError: false,
    isErrorMessage: ''
  })

  const [email, setEmail] = useState({
    value: '',
    isError: false,
    isErrorMessage: ''
  })

  const [password, setPassword] = useState({
    value: '',
    isError: false
  })

  //show or hide password
  const [displayPassword, setDisplayPassword] = useState(false)

  //input for showing password
  const passwordInput = {
    type: displayPassword ? 'text' : 'password'
  }

  //check entry
  const checkMail = value => {
    return !emailRegex.test(value)
  }

  const checkPassword = (e, value) => {
    e.preventDefault()
    //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special char
    return !PASSWORD_REGEX_3.test(value)
  }

  const checkLastname = lastname => {
    if (lastname.value === '') {
      setLastname({
        ...lastname,
        isErrorMessage: 'fieldRequired',
        isError: true
      })
    } else if (!nameRegex.test(lastname.value)) {
      setLastname({
        ...lastname,
        isErrorMessage: 'textError',
        isError: true
      })
    } else {
      setLastname({
        ...lastname,
        isError: false,
        isErrorMessage: ''
      })
    }
  }

  const checkFirstname = firstname => {
    if (firstname.value === '') {
      setFirstname({
        ...firstname,
        isErrorMessage: 'fieldRequired',
        isError: true
      })
    } else if (!nameRegex.test(firstname.value)) {
      setFirstname({
        ...firstname,
        isErrorMessage: 'textError',
        isError: true
      })
    } else {
      setFirstname({
        ...firstname,
        isError: false,
        isErrorMessage: ''
      })
    }
  }

  //Action
  const RegisterNewAccount = e => {
    e.preventDefault()

    //check empty input
    if (
      firstname.value !== '' &&
      lastname.value !== '' &&
      email.value !== '' &&
      password.value !== ''
    ) {
      //check conform input
      if (
        !firstname.isError &&
        !lastname.isError &&
        !email.isError &&
        !password.isError
      ) {
        dispatch(
          Actions.register(
            firstname.value,
            lastname.value,
            email.value,
            password.value,
            enqueueSnackbar,
            t,
            history
          )
        )
      } else {
        enqueueSnackbar(`${t('incorrectInput')}`, {
          variant: 'error'
        })
      }
    } else {
      enqueueSnackbar(`${t('ErrorAreaEmpty')}`, {
        variant: 'error'
      })
    }
  }

  return (
    <SignUpContent>
      <InputContainer>
        <Label styleType={'Primary'}>{t('lastNameLabel')}</Label>
        <Input
          styleType={'Auth'}
          onChange={e =>
            setLastname({
              ...lastname,
              value: e.target.value
            })
          }
          onBlur={() => checkLastname(lastname)}
          placeholder={t('lastNamePlaceholder')}
        />
        {lastname.isError ? (
          <ErrorText>{t(lastname.isErrorMessage)}</ErrorText>
        ) : null}
      </InputContainer>

      <InputContainer>
        <Label styleType={'Primary'}>{t('firstNameLabel')}</Label>
        <Input
          styleType={'Auth'}
          onChange={e =>
            setFirstname({
              ...firstname,
              value: e.target.value
            })
          }
          onBlur={() => checkFirstname(firstname)}
          placeholder={t('firstNamePlaceholder')}
        />
        {firstname.isError ? (
          <ErrorText>{t(firstname.isErrorMessage)}</ErrorText>
        ) : null}
      </InputContainer>

      <InputContainer>
        <Label styleType='Primary'>{t('emailLabel')}</Label>
        <Input
          styleType='Auth'
          onChange={e =>
            setEmail({
              ...email,
              value: e.target.value
            })
          }
          onBlur={e =>
            checkMail(email.value)
              ? setEmail({
                  ...email,
                  isError: true,
                  isErrorMessage: t('emailError')
                })
              : setEmail({
                  ...email,
                  isError: false,
                  isErrorMessage: ''
                })
          }
          placeholder={t('emailPlaceholder')}
        />
        {email.isError ? (
          <ErrorText>{t(email.isErrorMessage)}</ErrorText>
        ) : null}
      </InputContainer>

      <InputContainer>
        <Label styleType='Primary'>{t('passwordLabel')}</Label>
        <PasswordContainer>
          <Input
            styleType='Password'
            inputCfg={passwordInput}
            onChange={e =>
              checkPassword(e, e.target.value)
                ? setPassword({
                    ...password,
                    value: e.target.value,
                    isError: true
                  })
                : setPassword({
                    ...password,
                    value: e.target.value,
                    isError: false
                  })
            }
          ></Input>
          {displayPassword === true ? (
            <Img
              styleType='Eye'
              src={Visibility}
              onClick={() => setDisplayPassword(!displayPassword)}
            ></Img>
          ) : (
            <Img
              styleType='Eye'
              src={NoVisibility}
              onClick={() => setDisplayPassword(!displayPassword)}
            ></Img>
          )}
          {password.isError ? (
            <PopUp direction='left' password={password.value}></PopUp>
          ) : null}
        </PasswordContainer>
      </InputContainer>

      <Button
        type='submit'
        onClick={e => RegisterNewAccount(e)}
        styleType={'Auth'}
      >
        {t('signUpButton')}
      </Button>
    </SignUpContent>
  )
}

const SignUpContent = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
  }
`

export default SignUpForm
