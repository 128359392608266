import React, { useEffect } from 'react'

//components
import ScreenContainer from '../../components/common/ScreenContainer'
import UserRoleForm from '../../components/UserRoleForm'
import Title from '../../components/common/Titles/Title'
import SubTitle from '../../components/common/Titles/SubTitle'
import TitleContainer from '../../components/common/TitleContainer'

//redux
import * as Actions from '../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

//translation
import { useTranslation } from 'react-i18next'

const UserRole = props => {
  const { token } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const location = useLocation()
  //split the pathname
  const splitpath = location.pathname.split('/')
  //get the id in the pathname
  const id = splitpath[3]

  const user = useSelector(state => state.getOneUser)
  //get users informations
  useEffect(() => {
    dispatch(Actions.getOneUser(id))
  }, [id, dispatch])

  return (
    <ScreenContainer token={token}>
      <TitleContainer styleType='Page'>
        <Title>{t('roleTitle')}</Title>
        <SubTitle styleType='Primary'>{t('roleDesc')}</SubTitle>
      </TitleContainer>
      <UserRoleForm user={user} id={id} token={token}></UserRoleForm>
    </ScreenContainer>
  )
}

export default UserRole
