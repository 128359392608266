import React, { useEffect } from 'react'
import styled from 'styled-components'

//components
import ScreenContainer from '../../components/common/ScreenContainer'
import Title from '../../components/common/Titles/Title'
import SubTitle from '../../components/common/Titles/SubTitle'
import TitleContainer from '../../components/common/TitleContainer'
import MyTable from '../../components/common/MyTable'
import Text from '../../components/common/Text'

//redux
import * as Actions from '../../redux/actions'
import { useSelector, useDispatch } from 'react-redux'

//translation
import { useTranslation } from 'react-i18next'

//pageLoader
import PageLoader from '../../components/common/PageLoader'

const Admin = props => {
  const { token } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const users = useSelector(state => state.getAllUser)

  //get all users
  useEffect(() => {
    dispatch(Actions.getAllUser())
  }, [dispatch])

  return (
    <ScreenContainer token={token}>
      <TitleContainer styleType='Page'>
        <Title>{t('adminLabel')}</Title>
        <SubTitle styleType='Primary'>{t('adminDesc')}</SubTitle>
      </TitleContainer>
      {users?.Users != null ? (
        <AdminContent>
          <MyTable users={users.Users}></MyTable>
        </AdminContent>
      ) : users?.error != null ? (
        <Text styleType='ApiError'>{t('Unable to get users')}</Text>
      ) : (
        <PageLoader></PageLoader>
      )}
    </ScreenContainer>
  )
}

const AdminContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export default Admin
