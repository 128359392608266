import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TitleContainer = props => {
  const { styleType, children } = props

  const selectTitleContainer = children => {
    switch (styleType) {
      case 'Auth':
        return <AuthTitleContainer>{children}</AuthTitleContainer>

      case 'Page':
        return <PageTitleContainer>{children}</PageTitleContainer>

      case 'Card':
        return <CardTitleContainer>{children}</CardTitleContainer>

      case 'Error':
        return <ErrorTitleContainer>{children}</ErrorTitleContainer>

      default:
        return <BaseTitleContainer>{children}</BaseTitleContainer>
    }
  }
  return selectTitleContainer(children)
}

TitleContainer.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BaseTitleContainer = styled.div``

const AuthTitleContainer = styled(BaseTitleContainer)`
  margin-bottom: ${({ theme }) => theme.margin.mediumlarge};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    margin-bottom: ${({ theme }) => theme.margin.medium};
  }
`

const PageTitleContainer = styled(BaseTitleContainer)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.margin.xlarge};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    margin-bottom: ${({ theme }) => theme.margin.medium};
  }

  @media ${({ theme }) => theme.device.medium} {
    margin-bottom: ${({ theme }) => theme.margin.mediumlarge};
  }
`

const CardTitleContainer = styled(BaseTitleContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme }) => theme.margin.medium};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    padding: 0 ${({ theme }) => theme.padding.small};
  }
`

const ErrorTitleContainer = styled(BaseTitleContainer)`
  margin-bottom: ${({ theme }) => theme.margin.medium};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    margin-bottom: ${({ theme }) => theme.margin.small};
  }
`

export default TitleContainer
