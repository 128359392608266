import React from 'react'
import ScreenContainer from '../../components/common/ScreenContainer'
import SignInForm from '../../components/SignInForm'

//components
import Img from '../../components/common/Img'
import Title from '../../components/common/Titles/Title'
import SubTitle from '../../components/common/Titles/SubTitle'
import MyLink from '../../components/common/MyLink'
import TitleContainer from '../../components/common/TitleContainer'
import ContentContainer from '../../components/common/ContentContainer'

//images
import IzenciaLogo from '../../assets/images/izenciaLogo300px71px.png'

//translation
import { useTranslation } from 'react-i18next'

const SignIn = () => {
  const { t } = useTranslation()

  return (
    <ScreenContainer styleType={'Auth'}>
      <Img styleType='Auth' src={IzenciaLogo}></Img>
      <ContentContainer>
        <TitleContainer styleType='Auth'>
          <Title styleType='Auth'>{t('signInTitle')}</Title>
          <SubTitle styleType='Auth'>{t('signInSubTitle')}</SubTitle>
        </TitleContainer>
        <SignInForm></SignInForm>
      </ContentContainer>
      <MyLink styleType='Auth' to='/signup' bold={t('linkToSignUpBold')}>
        {t('linkToSignUp')}
      </MyLink>
    </ScreenContainer>
  )
}

export default SignIn
