import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ContentContainer = props => {
  const { styleType, children } = props

  const selectContentContainer = children => {
    switch (styleType) {
      default:
        return <BaseContentContainer>{children}</BaseContentContainer>
    }
  }
  return selectContentContainer(children)
}

ContentContainer.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BaseContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 20rem;
`
export default ContentContainer
