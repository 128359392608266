import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Span = props => {
  const { styleType, children } = props

  const selectSpan = children => {
    switch (styleType) {
      case 'Header':
        return <HeaderSpan>{children}</HeaderSpan>

      default:
        return <BaseSpan>{children}</BaseSpan>
    }
  }
  return selectSpan(children)
}
Span.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BaseSpan = styled.span`
  font-size: ${({ theme }) => theme.fontsize.medium};
`

const HeaderSpan = styled(BaseSpan)`
  @media ${({ theme }) => theme.device.medium} {
    font-size: ${({ theme }) => theme.fontsize.small};
  }
`

export default Span
