import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Footer from '../../Footer'
import Header from '../../Header'

const ScreenContainer = props => {
  const { styleType, children, token } = props

  const selectContainer = () => {
    switch (styleType) {
      case 'Auth': //without header and footer
        return (
          <AuthScreenContainer>
            <AuthContainer>{children}</AuthContainer>
          </AuthScreenContainer>
        )

      default:
        return (
          <>
            <Header token={token} />

            <BaseScreenContainer>
              <BaseContainer>{children}</BaseContainer>
            </BaseScreenContainer>
            <Footer />
          </>
        )
    }
  }

  return selectContainer()
}

ScreenContainer.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BaseScreenContainer = styled.div`
  margin: 0 ${({ theme }) => theme.margin.container};
  margin-top: ${({ theme }) => theme.margin.large};
  margin-bottom: ${({ theme }) => theme.footerHeight};

  @media ${({ theme }) => theme.device.xxlarge} {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    margin-left: ${({ theme }) => theme.margin.medium};
    margin-right: ${({ theme }) => theme.margin.medium};
    margin-top: ${({ theme }) => theme.margin.medium};
  }

  @media ${({ theme }) => theme.device.medium} {
    margin-left: ${({ theme }) => theme.margin.large};
    margin-right: ${({ theme }) => theme.margin.large};
    margin-top: ${({ theme }) => theme.margin.mediumsmall};
  }

  @media ${({ theme }) => theme.device.large} {
    margin-left: ${({ theme }) => theme.margin.xlarge};
    margin-right: ${({ theme }) => theme.margin.xlarge};
    margin-top: ${({ theme }) => theme.margin.medium};
  }
`

const BaseContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.padding.medium};
  @media ${({ theme }) => theme.device.xxlarge} {
    max-width: 75.75rem;
  }
`

const AuthScreenContainer = styled(BaseScreenContainer)`
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    background-color: ${({ theme }) => theme.noColor};
  }
`

const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.noColor};
  padding: ${({ theme }) => theme.padding.large};
  border: 1px solid ${({ theme }) => theme.primaryBackgroundColor};
  box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.borderRadius.medium};

  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    padding: ${({ theme }) => theme.padding.small};

    border: none;
    box-shadow: none;
  }

  @media ${({ theme }) => theme.device.medium} {
    padding: ${({ theme }) => theme.padding.mediumlarge};
  }
`

export default ScreenContainer
