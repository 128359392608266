import { GET_ALL_USER, GET_ALL_USER_ERROR } from '../actions/getAllUser'

const initialState = {
  Users: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USER:
      return {
        ...state,
        Users: action.payload
      }

    case GET_ALL_USER_ERROR:
      return {
        ...state,
        Users: null,
        error: action.payload
      }

    default:
      return state
  }
}
