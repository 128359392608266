import React from 'react'
import styled from 'styled-components'
import Label from '../common/Label'
import MyLink from '../common/MyLink'

//translation
import { useTranslation } from 'react-i18next'

const Footer = props => {
  const { t } = useTranslation()
  return (
    <FooterContainer>
      <Label styleType='Copyrights'>&copy;Copyright 2020 izencia</Label>
      <InformationBox>
        <MyLink styleType='Policy' to='/privacy-policy'>
          {t('policyTitle')} |
        </MyLink>
        <MyLink styleType='Policy' to='#' id='open_preferences_center'>
          {t('preference')}
        </MyLink>
      </InformationBox>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  width: 100%;
  height: ${({ theme }) => theme.footerHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.quaternaryBackgroundColor}26;
`

const InformationBox = styled.div``
export default Footer
