import React from 'react'
import styled from 'styled-components'

//cliploader
import { css } from '@emotion/react'
import ClipLoader from 'react-spinners/ClipLoader'

const PageLoader = () => {
  let isLoading = true
  const color = `${({ theme }) => theme.secondaryBackgroundColor}`

  return (
    <PageLoaderContainer>
      <ClipLoader css={override} size={50} color={color} loading={isLoading} />
    </PageLoaderContainer>
  )
}

const PageLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10rem;
`

const override = css`
  display: block;
  margin: 0 auto;
`
export default PageLoader
