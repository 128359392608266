import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SubTitle = props => {
  const { styleType, children } = props

  const selectSubTitle = children => {
    switch (styleType) {
      case 'Primary':
        return <PrimarySubTitle>{children}</PrimarySubTitle>

      case 'Secondary':
        return <SecondarySubTitle>{children}</SecondarySubTitle>

      case 'Screen':
        return <ScreenSubTitle>{children}</ScreenSubTitle>

      case 'Auth':
        return <AuthSubTitle>{children}</AuthSubTitle>

      case 'Error':
        return <ErrorSubTitle>{children}</ErrorSubTitle>

      default:
        return <BaseSubTitle>{children}</BaseSubTitle>
    }
  }
  return selectSubTitle(children)
}

SubTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  styleType: PropTypes.string
}

const BaseSubTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontsize.medium};
  color: ${({ theme }) => theme.secondaryTextColor};
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    font-size: ${({ theme }) => theme.fontsize.small};
    line-height: ${({ theme }) => theme.lineHeight.small};
  }
`

const PrimarySubTitle = styled(BaseSubTitle)`
  color: ${({ theme }) => theme.quaternaryTextColor};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`

const SecondarySubTitle = styled(PrimarySubTitle)`
  margin-bottom: ${({ theme }) => theme.margin.medium};
`

const ScreenSubTitle = styled(BaseSubTitle)`
  line-height: ${({ theme }) => theme.lineHeight.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: justify;
  white-space: break-spaces;
`

const AuthSubTitle = styled(BaseSubTitle)`
  color: ${({ theme }) => theme.primaryTextColor};
`

const ErrorSubTitle = styled(AuthSubTitle)`
  text-align: center;
`

export default SubTitle
