import Axios from 'axios'

// Liste des types
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

// Change le thème
export const resetPassword = (
  newPassword,
  resetToken,
  enqueueSnackbar,
  t,
  history
) => {
  return async dispatch => {
    try {
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: null
      })
      const options = {
        method: 'PUT',
        data: {
          password: newPassword
        },
        url: `${process.env.REACT_APP_API_BASE_URL}/auth/resetpassword/${resetToken}`
      }

      const { data } = await Axios(options)
      dispatch({
        type: RESET_PASSWORD,
        payload: data
      })

      enqueueSnackbar(`${t('resetAlert')}`, {
        variant: 'success'
      })

      history.push('/')
    } catch (error) {
      const errorDetails = {
        status: error?.response.status,
        statusText: error?.response.statusText
      }

      enqueueSnackbar(t('Unauthorized'), {
        variant: 'error'
      })

      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: errorDetails
      })
    }
  }
}
