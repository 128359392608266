import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const CardTitle = props => {
  const { children, styleType } = props

  const selectCardTitle = children => {
    switch (styleType) {
      case 'Modal':
        return <ModalCardTitle>{children}</ModalCardTitle>
      default:
        return <BaseCardTitle>{children}</BaseCardTitle>
    }
  }
  return selectCardTitle(children)
}

CardTitle.propTypes = {
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

const BaseCardTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontsize.mediumlarge};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.padding.small};
  width: 100%;
  line-height: ${({ theme }) => theme.lineHeight.large};

  /*limit length text */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const ModalCardTitle = styled(BaseCardTitle)`
  text-align: left;
  white-space: break-spaces;
  font-size: ${({ theme }) => theme.fontsize.mediumlarge};
  margin: 0;
  font-weight: 600;
`
export default CardTitle
