import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

//components
import Button from '../common/Button'
import Label from '../common/Label'
import Input from '../common/Input'
import Img from '../common/Img'
import InputContainer from '../common/InputContainer'
import PasswordContainer from '../common/PasswordContainer'
import PopUp from '../common/PopUp'

//redux
import * as Actions from '../../redux/actions'

//images
import Visibility from '../../assets/images/visibility40px40px.png'
import NoVisibility from '../../assets/images/noVisibility40px40px.png'

//translation
import { useTranslation } from 'react-i18next'

//snackbar
import { useSnackbar } from 'notistack'

//regex
import { PASSWORD_REGEX_3 } from '../../config/regex'

const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  //split the pathname
  const splitpath = location.pathname.split('/')
  //get the id in the pathname
  const resetToken = splitpath[2]

  const { enqueueSnackbar } = useSnackbar()

  const [password, setPassword] = useState({
    value: '',
    isError: false
  })

  //show or hide password
  const [displayPassword, setDisplayPassword] = useState(false)

  const passwordInput = {
    type: displayPassword ? 'text' : 'password'
  }

  const checkPassword = (e, value) => {
    e.preventDefault()
    //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special char
    return !PASSWORD_REGEX_3.test(value)
  }

  //action
  const sendNewPassword = e => {
    e.preventDefault()
    if (!password.isError) {
      dispatch(
        Actions.resetPassword(
          password.value,
          resetToken,
          enqueueSnackbar,
          t,
          history
        )
      )
    } else {
      enqueueSnackbar(`${t('incorrectInput')}`, {
        variant: 'error'
      })
    }
  }

  return (
    <ResetPasswordContent>
      <InputContainer>
        <Label styleType='Primary'>{t('passwordLabel')}</Label>
        <PasswordContainer>
          <Input
            styleType='Password'
            inputCfg={passwordInput}
            onChange={e =>
              checkPassword(e, e.target.value)
                ? setPassword({
                    ...password,
                    value: e.target.value,
                    isError: true
                  })
                : setPassword({
                    ...password,
                    value: e.target.value,
                    isError: false
                  })
            }
          ></Input>
          {displayPassword === true ? (
            <Img
              styleType='Eye'
              src={Visibility}
              onClick={() => setDisplayPassword(!displayPassword)}
            ></Img>
          ) : (
            <Img
              styleType='Eye'
              src={NoVisibility}
              onClick={() => setDisplayPassword(!displayPassword)}
            ></Img>
          )}
          {password.isError ? (
            <PopUp direction='left' password={password.value}></PopUp>
          ) : null}
        </PasswordContainer>
      </InputContainer>

      <Button
        type='submit'
        onClick={e => sendNewPassword(e)}
        styleType={'Auth'}
      >
        {t('submitButton')}
      </Button>
    </ResetPasswordContent>
  )
}

const ResetPasswordContent = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
  }
`

export default ResetPasswordForm
