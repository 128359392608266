import React from 'react'
import styled from 'styled-components'
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination
} from 'react-table'

// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter'

//components
import Button from '../Button'
import Input from '../Input'
import Img from '../Img'

//images
import Up from '../../../assets/images/up.png'

import Down from '../../../assets/images/down.png'
//translation
import { useTranslation } from 'react-i18next'

//redux
import { useHistory } from 'react-router-dom'

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  t
}) {
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <Input
      styleType='Table'
      value={value || ''}
      onChange={e => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={t('placeHolderSearch')}
    />
  )
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter }
}) {
  const count = preFilteredRows.length

  return (
    <input
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function Table({ columns, data, t }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      }
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: process.env.REACT_APP_USER_PER_PAGE
      }
    },

    useFilters, // useFilters!

    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  )

  //const firstPageRows = rows.slice(0, 10)

  const history = useHistory()

  //action
  const goToUser = _id => {
    history.push(`/admin/user/${_id}`)
  }
  // Render the UI for your table
  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        t={t}
      />
      <TableContainer>
        <StyledTable {...getTableProps()}>
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <ThContent>
                      {column.render('Header')}
                      {/* Add a sort direction indicator */}

                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowContainer>
                              <Img
                                styleType='Table'
                                src={Up}
                                opacity={0.4}
                              ></Img>
                              <Img
                                styleType='Table'
                                src={Down}
                                opacity={1}
                              ></Img>
                            </ArrowContainer>
                          ) : (
                            <ArrowContainer>
                              <Img styleType='Table' src={Up} opacity={1}></Img>
                              <Img
                                styleType='Table'
                                src={Down}
                                opacity={0.4}
                              ></Img>
                            </ArrowContainer>
                          )
                        ) : (
                          <ArrowContainer>
                            <Img styleType='Table' src={Up} opacity={0.4}></Img>
                            <Img
                              styleType='Table'
                              src={Down}
                              opacity={0.4}
                            ></Img>
                          </ArrowContainer>
                        )}
                      </span>
                    </ThContent>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <Tr
                  onClick={() => goToUser(row.original._id)}
                  {...row.getRowProps()}
                >
                  {row.cells.map(cell => {
                    return (
                      <Td {...cell.getCellProps()}>
                        <HeaderText>{cell.column.Header}</HeaderText>
                        {cell.column.Header === `${t('roleLabel')}` ? (
                          <Text>{t(cell.value)}</Text>
                        ) : (
                          <Text>{cell.value}</Text>
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </StyledTable>

        <TablePagination>
          <Pages>
            <Button
              styleType='Table'
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {t('previousButton')}
            </Button>

            {pageIndex + 1 >= pageCount && pageCount > 2 ? (
              <Button styleType='Table' onClick={() => gotoPage(pageIndex - 2)}>
                {pageIndex - 1}
              </Button>
            ) : null}

            {pageIndex - 1 >= 0 ? (
              <Button styleType='Table' onClick={() => previousPage()}>
                {pageIndex}
              </Button>
            ) : null}

            <Button styleType='Table' current={true}>
              {pageIndex + 1}{' '}
            </Button>

            {pageIndex + 1 < pageCount ? (
              <Button styleType='Table' onClick={() => nextPage()}>
                {pageIndex + 2}
              </Button>
            ) : null}

            {pageIndex - 1 < 0 && pageCount > 2 ? (
              <Button styleType='Table' onClick={() => gotoPage(pageIndex + 2)}>
                {pageIndex + 3}
              </Button>
            ) : null}

            <Button
              styleType='Table'
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {t('nextButton')}
            </Button>
          </Pages>
        </TablePagination>
      </TableContainer>
    </>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

const MyTable = props => {
  const { users } = props
  const { t } = useTranslation()

  const columns = React.useMemo(
    () => [
      {
        Header: t('lastNameLabel'),
        accessor: 'lastname'
      },
      {
        Header: t('firstNameLabel'),
        accessor: 'firstname'
      },
      {
        Header: t('roleLabel'),
        accessor: 'role'
      },
      {
        Header: t('emailLabel'),
        accessor: 'email'
      }
    ],

    [t]
  )

  return <Table columns={columns} data={users.data} t={t} />
}

///Styles Components
const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  border-radius: ${({ theme }) => theme.borderRadius.xsmall};
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    border: none;
  }
`
const StyledTable = styled.table`
  display: table;
  text-align: left;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: block;
  }
`
const Thead = styled.thead`
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: none;
  }
`

const Tbody = styled.tbody`
  width: 100%;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: block;
    border: none;
  }
`

//row
const Tr = styled.tr`
  width: 100%;
  color: ${({ theme }) => theme.primaryTextColor};
  background-color: ${({ theme }) => theme.noColor};
  cursor: pointer;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: block;
    margin-bottom: ${({ theme }) => theme.margin.medium};
    border-radius: ${({ theme }) => theme.borderRadius.xsmall};
    border: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  }
`

//column
const Th = styled.th`
  color: ${({ theme }) => theme.primaryTextColor};
  background-color: ${({ theme }) => theme.quaternaryBackgroundColor}10;
  padding: ${({ theme }) => theme.padding.small}
    ${({ theme }) => theme.padding.medium};
  border-right: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  width: 20%;
  max-width: 20%;
  :first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.xsmall};
  }
  :last-child {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.xsmall};
    border-right: none;
    width: 40%;
    max-width: 40%;
  }

  :nth-child(3) {
    width: 10%;
    max-width: 10%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.quaternaryBackgroundColor}50;
  }
`
const ThContent = styled.div`
  display: flex;
  justify-content: space-between;
`

//Arrow
const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
`

//cell
const Td = styled.td`
  color: ${({ theme }) => theme.primaryTextColor};
  padding: ${({ theme }) => theme.padding.small}
    ${({ theme }) => theme.padding.medium};
  border-right: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  border-top: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  width: 25%;
  max-width: 25%;

  :nth-child(3) {
    width: 10%;
    max-width: 10%;
  }

  :last-child {
    border-right: none;
    width: 40%;
    max-width: 40%;
  }

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: flex;
    width: 100%;

    border-right: none;

    :first-child {
      border-top: none;
    }

    max-width: none;
    justify-content: space-between;

    :nth-child(3) {
      width: 100%;
      max-width: 100%;
    }
    :last-child {
      width: 100%;
      max-width: 100%;
    }
  }
`

//text cell
const Text = styled.p`
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  @media ${({ theme }) => theme.device.medium} {
    //to break word we need width
    max-width: 28ch;
  }

  @media ${({ theme }) => theme.device.small}, {
    text-align: right;
    max-width: 40ch;
  }

  @media ${({ theme }) => theme.device.xsmall}, {
    text-align: right;
    max-width: 20ch;
  }
`
//Header Text for mobile devices
const HeaderText = styled(Text)`
  display: none;
  font-weight: bold;
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: flex;
  }
`

const TablePagination = styled.div`
  background-color: ${({ theme }) => theme.noColor};
  border-top: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.xsmall};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.xsmall};
  padding: ${({ theme }) => theme.padding.small}
    ${({ theme }) => theme.padding.medium};
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    border-radius: ${({ theme }) => theme.borderRadius.xsmall};
    border: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  }
`

const Pages = styled.div`
  border: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
  border-radius: ${({ theme }) => theme.borderRadius.xsmall};
`
export default MyTable
