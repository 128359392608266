import React, { useState } from 'react'
import styled from 'styled-components'

//components
import Input from '../common/Input'
import Label from '../common/Label'
import Button from '../common/Button'
import Img from '../common/Img'
import InputContainer from '../common/InputContainer'
import PasswordContainer from '../common/PasswordContainer'
import PopUp from '../common/PopUp'

//redux
import * as Actions from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//translation
import { useTranslation } from 'react-i18next'

//snackbar
import { useSnackbar } from 'notistack'

//images
import Visibility from '../../assets/images/visibility40px40px.png'
import NoVisibility from '../../assets/images/noVisibility40px40px.png'

//regex
import { PASSWORD_EMPTY_REGEX } from '../../config/regex'

const NewPasswordForm = props => {
  let { token } = props

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const checkPasswordOrEmpty = (e, value) => {
    e.preventDefault()
    //Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special char
    return !PASSWORD_EMPTY_REGEX.test(value)
  }

  const [lastPassword, setLastPassword] = useState({
    value: ''
  })

  const [newPassword, setNewPassword] = useState({
    value: '',
    isError: false
  })

  //show or hide password
  const [displayLastPassword, setDisplayLastPassword] = useState(false)
  const [displayNewPassword, setDisplayNewPassword] = useState(false)

  const newPasswordInput = {
    type: displayNewPassword ? 'text' : 'password'
  }

  const lastPasswordInput = {
    type: displayLastPassword ? 'text' : 'password'
  }

  //update user information
  const updatePassword = e => {
    e.preventDefault()

    //check conform input
    if (!lastPassword.isError && !newPassword.isError) {
      //update user informations
      dispatch(
        Actions.updatePassword(
          lastPassword.value,
          newPassword.value,
          token?.id,
          enqueueSnackbar,
          t,
          history
        )
      )
    } else {
      enqueueSnackbar(`${t('incorrectInput')}`, {
        variant: 'error'
      })
    }
  }

  return (
    <ProfilFormContainer>
      <InputContainer styleType='Profil'>
        <Label styleType='Primary'>{t('lastPassword')}</Label>
        <PasswordContainer styleType='Profil'>
          <Input
            styleType='Password'
            inputCfg={lastPasswordInput}
            onChange={e =>
              setLastPassword({
                ...lastPassword,
                value: e.target.value
              })
            }
          ></Input>
          {displayLastPassword === true ? (
            <Img
              styleType='Eye'
              src={Visibility}
              onClick={() => setDisplayLastPassword(!displayLastPassword)}
            ></Img>
          ) : (
            <Img
              styleType='Eye'
              src={NoVisibility}
              onClick={() => setDisplayLastPassword(!displayLastPassword)}
            ></Img>
          )}
        </PasswordContainer>
      </InputContainer>

      <InputContainer styleType='Profil'>
        <Label styleType='Primary'>{t('newPassword')}</Label>
        <PasswordContainer styleType='Profil'>
          <Input
            styleType={'Password'}
            inputCfg={newPasswordInput}
            onChange={e =>
              checkPasswordOrEmpty(e, e.target.value)
                ? setNewPassword({
                    ...newPassword,
                    value: e.target.value,
                    isError: true
                  })
                : setNewPassword({
                    ...newPassword,
                    value: e.target.value,
                    isError: false
                  })
            }
          ></Input>
          {displayNewPassword === true ? (
            <Img
              styleType='Eye'
              src={Visibility}
              onClick={() => setDisplayNewPassword(!displayNewPassword)}
            ></Img>
          ) : (
            <Img
              styleType='Eye'
              src={NoVisibility}
              onClick={() => setDisplayNewPassword(!displayNewPassword)}
            ></Img>
          )}
          {newPassword.isError ? (
            <PopUp direction='top' password={newPassword.value}></PopUp>
          ) : null}
        </PasswordContainer>
      </InputContainer>

      <Button
        styleType='Primary'
        onClick={e => updatePassword(e)}
        type='submit'
      >
        {t('updateButton')}
      </Button>
    </ProfilFormContainer>
  )
}

const ProfilFormContainer = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`

export default NewPasswordForm
