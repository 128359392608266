import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

//components

import Input from '../common/Input'
import Label from '../common/Label'
import Button from '../common/Button'
import Select from '../common/Select'
import ErrorText from '../common/ErrorText'
import InputContainer from '../common/InputContainer'

//redux
import * as Actions from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//translation
import { useTranslation } from 'react-i18next'

//snackbar
import { useSnackbar } from 'notistack'

//cliploader
import PageLoader from '../common/PageLoader'

const UserRoleForm = props => {
  let { user, id } = props
  console.log('user', user?.firstname)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const [role, setRole] = useState({
    value: '',
    changed: false
  })

  const updateUserRole = e => {
    e.preventDefault()

    //check if user make changes
    let roleSend = ''

    if (role.changed === true) {
      roleSend = role.value
    } else {
      // no change role.value is undefined
      roleSend = user.User.role
    }

    //update user's role
    dispatch(Actions.updateRole(roleSend, id, t, enqueueSnackbar, history))
  }
  return (
    <UserContainer>
      {user.User !== null ? (
        <UserContent>
          <InputContainer styleType='Profil'>
            <Label styleType='Primary'>{t('lastNameLabel')}</Label>
            <Input styleType='Disabled' value={user?.User.lastname}></Input>
          </InputContainer>
          <InputContainer styleType='Profil'>
            <Label styleType='Primary'>{t('firstNameLabel')}</Label>
            <Input styleType='Disabled' value={user?.User.firstname}></Input>
          </InputContainer>
          <InputContainer styleType='Profil'>
            <Label styleType='Primary'>{t('emailLabel')}</Label>
            <Input styleType='Disabled' value={user?.User.email}></Input>
          </InputContainer>
          <InputContainer styleType='Profil'>
            <Label styleType='Primary'>{t('roleLabel')}</Label>
            <Select
              onChange={e =>
                setRole({
                  ...role,
                  value: e.target.value,
                  changed: true
                })
              }
              defaultValue={user.User?.role}
            ></Select>
          </InputContainer>
          <ButtonContainer>
            <Button styleType={'Primary'} onClick={e => updateUserRole(e)}>
              {t('updateButton')}
            </Button>
          </ButtonContainer>
        </UserContent>
      ) : user.error ? (
        <ErrorText styleType={'NotFound'}>{t('noUserFound')}</ErrorText>
      ) : (
        <PageLoader />
      )}
    </UserContainer>
  )
}

UserRoleForm.propTypes = {
  webinarResponse: PropTypes.object,
  webinarId: PropTypes.object
}

const UserContainer = styled.div`
  width: 100%;
  height: 100%;
  @media ${({ theme }) => theme.device.xxlarge} {
    max-width: 75.75rem;
  }
`

const UserContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; //mobile
`

const ButtonContainer = styled.div`
  width: 100%;
`

export default UserRoleForm
