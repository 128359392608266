import React from 'react'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import Home from '../../screens/Home'
import Admin from '../../screens/Admin'
import UserRole from '../../screens/UserRole'
import ResetPassword from '../../screens/ResetPassword'
import ForgetPassword from '../../screens/ForgetPassword'
import Webinar from '../../screens/Webinar'
import Profil from '../../screens/Profil'
import SignUp from '../../screens/SignUp'
import SignIn from '../../screens/SignIn'
import EditWebinar from '../../screens/EditWebinar'
import NotFound from '../../screens/NotFound'
import Policy from '../../screens/Policy'

import PrivateRoute from './privateRoute'
import styled from 'styled-components'
import NewPassword from '../../screens/NewPassword'

const Routes = () => {
  return (
    <Router>
      <MainBloc>
        <Switch>
          <Route exact path='/' component={SignIn} />
          <Route exact path='/signin' component={SignIn} />
          <Route exact path='/signup' component={SignUp} />
          <Route exact path='/privacy-policy' component={Policy} />
          <PrivateRoute path='/home' component={Home} right='user' />
          <PrivateRoute
            exact
            path='/webinar/:id'
            component={Webinar}
            right='user'
          />
          <PrivateRoute exact path='/profil' component={Profil} right='user' />
          <PrivateRoute
            exact
            path='/newpassword'
            component={NewPassword}
            right='user'
          />
          <PrivateRoute
            exact
            path='/webinar/:id/edit'
            component={EditWebinar}
            right='publisher'
          />
          <PrivateRoute
            exact
            path='/admin/user/:id'
            component={UserRole}
            right='admin'
          />
          <PrivateRoute exact path='/admin' component={Admin} right='admin' />

          <Route exact path='/resetpassword/:id' component={ResetPassword} />
          <Route exact path='/forgetpassword' component={ForgetPassword} />

          <Route path='*' component={NotFound} />
        </Switch>
      </MainBloc>
    </Router>
  )
}

const MainBloc = styled.div`
  min-height: 100vh;
  overflow: hidden;
  display: block;
  position: relative;
`
export default Routes
