import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

//components
import Button from '../common/Button'
import Label from '../common/Label'
import Input from '../common/Input'
import ErrorText from '../common/ErrorText'
import InputContainer from '../common/InputContainer'

//redux
import * as Actions from '../../redux/actions'

//translation
import { useTranslation } from 'react-i18next'

//snackbar
import { useSnackbar } from 'notistack'

//regex
import { emailRegex } from '../../config/regex'

const ForgetPasswordForm = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  //check if already connected
  const userToken = useSelector(state => state.authentication?.token)

  useEffect(() => {
    if (userToken) {
      history.push('/home')
    }
  }, [userToken, history])

  const { enqueueSnackbar } = useSnackbar()

  const [email, setEmail] = useState({
    value: '',
    isError: false,
    isErrorMessage: ''
  })

  //check entry
  const checkMail = value => {
    return !emailRegex.test(value)
  }

  //Action
  const sendMail = e => {
    e.preventDefault()
    if (!email.isError) {
      dispatch(Actions.forgetPassword(email.value, enqueueSnackbar, t, history))
    } else {
      enqueueSnackbar(`${t('incorrectInput')}`, {
        variant: 'error'
      })
    }
  }
  return (
    <ForgetPasswordContent>
      <InputContainer>
        <Label styleType='Primary'>{t('emailLabel')}</Label>
        <Input
          styleType='Auth'
          onChange={e =>
            setEmail({
              ...email,
              value: e.target.value
            })
          }
          onBlur={e =>
            checkMail(email.value)
              ? setEmail({
                  ...email,
                  isError: true,
                  isErrorMessage: t('emailError')
                })
              : setEmail({
                  ...email,
                  isError: false,
                  isErrorMessage: ''
                })
          }
          placeholder={t('emailPlaceholder')}
        />
        {email.isError ? <ErrorText>{email.isErrorMessage}</ErrorText> : null}
      </InputContainer>
      <Button type='submit' onClick={e => sendMail(e)} styleType={'Auth'}>
        {t('submitButton')}
      </Button>
    </ForgetPasswordContent>
  )
}

const ForgetPasswordContent = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
  }
`

export default ForgetPasswordForm
