import { GET_ONE_USER, GET_ONE_USER_ERROR } from '../actions/getOneUser'

const initialState = {
  User: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ONE_USER:
      return {
        ...state,
        User: action.payload.data
      }

    case GET_ONE_USER_ERROR:
      return {
        ...state,
        User: null,
        error: action.payload
      }

    default:
      return state
  }
}
