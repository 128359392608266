import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = props => {
  const { styleType, onClick, children, open, current, disabled, type } = props

  const selectButton = children => {
    switch (styleType) {
      case 'HeaderPhone':
        return (
          <HeaderPhoneButton open={open} onClick={onClick}>
            {children}
          </HeaderPhoneButton>
        )

      case 'Header':
        return <HeaderButton onClick={onClick}>{children}</HeaderButton>

      case 'Primary':
        return (
          <PrimaryButton onClick={onClick} type={type}>
            {' '}
            {children}{' '}
          </PrimaryButton>
        )

      case 'Auth':
        return <AuthButton onClick={onClick}> {children} </AuthButton>

      case 'Pagination':
        return (
          <PaginationButton onClick={onClick}> {children} </PaginationButton>
        )

      case 'DotPagination':
        return <DotPaginationButton> {children} </DotPaginationButton>

      case 'CurrentPage':
        return (
          <CurrentPageButton onClick={onClick}> {children} </CurrentPageButton>
        )

      case 'Table':
        return (
          <TableButton onClick={onClick} current={current} disabled={disabled}>
            {children}
          </TableButton>
        )

      case 'Modal':
        return (
          <ModalButton onClick={onClick} type={type}>
            {' '}
            {children}
          </ModalButton>
        )

      default:
        return <BaseButton onClick={onClick}> {children} </BaseButton>
    }
  }
  return selectButton(children)
}

Button.propTypes = {
  onClick: PropTypes.func,
  styleType: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  open: PropTypes.bool,
  current: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string
}

const BaseButton = styled.button`
  cursor: pointer;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
  color: ${({ theme }) => theme.tertiaryTextColor};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.padding.small}
    ${({ theme }) => theme.padding.medium};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontsize.medium};
  border: 2px solid ${({ theme }) => theme.secondaryBackgroundColor};
  text-align: center;
  &:hover {
    color: ${({ theme }) => theme.primaryTextColor};
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border: 2px solid ${({ theme }) => theme.tertiaryBackgroundColor};
    font-weight: bold;
    box-shadow: none;
    transition: 0.3s;
  }
`

const HeaderButton = styled(BaseButton)`
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: none;
  }

  @media ${({ theme }) => theme.device.medium} {
    font-size: ${({ theme }) => theme.fontsize.small};
  }
`

const HeaderPhoneButton = styled(BaseButton)`
  display: none;
  z-index: 1;
  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    display: flex;
  }
  position: fixed;
  bottom: ${({ theme }) => theme.padding.large};
`

const PrimaryButton = styled(BaseButton)`
  min-width: 13rem;
  font-weight: normal;
  padding: ${({ theme }) => theme.padding.small}
    ${({ theme }) => theme.padding.medium};
  margin-top: ${({ theme }) => theme.margin.mediumlarge};
  margin-right: ${({ theme }) => theme.margin.large};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    min-width: 10rem;
    font-size: ${({ theme }) => theme.fontsize.small};
    margin-top: ${({ theme }) => theme.margin.medium};
    padding: ${({ theme }) => theme.padding.xsmall}
      ${({ theme }) => theme.padding.mediumsmall};
    margin-right: ${({ type, theme }) =>
      type === 'delete' ? '0' : theme.margin.small};
  }

  @media ${({ theme }) => theme.device.medium} {
    margin-top: ${({ theme }) => theme.margin.medium};
    margin-right: ${({ type, theme }) =>
      type === 'delete' ? '0' : theme.margin.medium};
  }

  //change with the type
  border-color: ${({ type, theme }) =>
    type === 'delete' ? theme.errorColor : theme.secondaryBackgroundColor};

  background-color: ${({ type, theme }) =>
    type === 'delete' ? theme.errorColor : theme.secondaryBackgroundColor};

  margin-right: ${({ type, theme }) =>
    type === 'delete' ? '0' : theme.margin.small};
`

const AuthButton = styled(PrimaryButton)`
  margin-right: 0;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.noColor};
  }
`
const DotPaginationButton = styled(BaseButton)`
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  color: ${({ theme }) => theme.quaternaryTextColor};
  border: none;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontsize.small};
  padding: 0 ${({ theme }) => theme.padding.small};
  &:hover {
    border: none;
    color: ${({ theme }) => theme.quaternaryTextColor};
    font-weight: normal;
    box-shadow: none;
    transition: 0.3s;
  }
`

const PaginationButton = styled(DotPaginationButton)`
  &:hover {
    color: ${({ theme }) => theme.primaryTextColor};
  }
`

const CurrentPageButton = styled(BaseButton)`
  font-size: ${({ theme }) => theme.fontsize.small};
  font-weight: normal;
  padding: ${({ theme }) => theme.padding.xxsmall}
    ${({ theme }) => theme.padding.xsmall};
`

const TableButton = styled(BaseButton)`
  font-size: ${({ theme }) => theme.fontsize.small};
  background-color: ${({ current, theme }) =>
    current ? theme.quaternaryBackgroundColor : theme.primaryBackgroundColor}20;
  color: ${({ theme }) => theme.primaryTextColor};
  font-weight: ${({ current }) => (current ? 'bold' : 'normal')};
  padding: 10px 14px;
  border: none;
  border-radius: 0;
  border-right: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;

  :first-child {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  :last-child {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-right: none;
  }

  &:hover {
    color: ${({ theme }) => theme.primaryTextColor};
    background-color: ${({ theme }) => theme.quaternaryBackgroundColor}20;
    border: none;
    border-right: 1px solid ${({ theme }) => theme.quaternaryBackgroundColor}50;
    font-weight: ${({ current }) => (current ? 'bold' : 'normal')};
    box-shadow: none;
    transition: none;
    :last-child {
      border-right: none;
    }
  }

  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.secondaryTextColor};
    &:hover {
      color: ${({ theme }) => theme.secondaryTextColor};
      background-color: ${({ current, theme }) =>
        current
          ? theme.quaternaryBackgroundColor
          : theme.primaryBackgroundColor}20;
    }
  }
`

const ModalButton = styled(BaseButton)`
  font-size: ${({ theme }) => theme.fontsize.small};
  font-weight: 600;
  padding: ${({ theme }) => theme.padding.xsmall}
    ${({ theme }) => theme.padding.small};

  border-color: ${({ type, theme }) =>
    type === 'delete' ? theme.errorColor : theme.secondaryBackgroundColor};

  background-color: ${({ type, theme }) =>
    type === 'delete' ? theme.errorColor : theme.secondaryBackgroundColor};

  margin-right: ${({ type, theme }) =>
    type === 'delete' ? '0' : theme.margin.small};

  border-radius: ${({ theme }) => theme.borderRadius.xsmall};

  &:hover {
    font-weight: 600;
  }
`

export default Button
