import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

//components
import ScreenContainer from '../../components/common/ScreenContainer'
import SubTitle from '../../components/common/Titles/SubTitle'
import Img from '../../components/common/Img'
import Input from '../../components/common/Input'
import Title from '../../components/common/Titles/Title'
import InputContainer from '../../components/common/InputContainer'
import TitleContainer from '../../components/common/TitleContainer'
import CardContainer from '../../components/common/CardContainer'
import ErrorText from '../../components/common/ErrorText'
import Pagination from '../../components/Pagination'

//cliploader
import PageLoader from '../../components/common/PageLoader'

//translation
import { useTranslation } from 'react-i18next'

//images
import TitleImage from '../../assets/images/titleImage750px359px.jpg'

//redux
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../redux/actions'

const Home = props => {
  const { token } = props
  //limit call api
  const timer = useRef(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  //searchbar query
  const [query, setQuery] = useState('')

  const webinarSuccess = useSelector(state => state.getWebinar)

  //pagination
  const perPage = parseInt(process.env.REACT_APP_WEBINAR_PER_PAGE, 10) //webinar per page
  const [currentPage, setCurrentPage] = useState(1)

  //check query
  useEffect(() => {
    //set timer at 500ms when updating input
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      dispatch(Actions.getWebinar(query, currentPage, perPage))
    }, 500)
  }, [query, currentPage, perPage, dispatch])

  return (
    <ScreenContainer token={token}>
      <HeaderHome>
        <HeaderContent>
          <TitleContainer>
            <Title>{t('webinars')}</Title>
            <SubTitle styleType='Screen'>{t('webinarDescription')}</SubTitle>
          </TitleContainer>

          <InputContainer styleType='Search'>
            <Input
              styleType={'Search'}
              onChange={e => setQuery(e.target.value)}
              placeholder={t('placeHolderWebinar')}
            ></Input>
          </InputContainer>
        </HeaderContent>
        <HeaderImage>
          <Img src={TitleImage} styleType='Title'></Img>
        </HeaderImage>
      </HeaderHome>
      {webinarSuccess.webinars != null ? (
        webinarSuccess.webinars.total !== 0 ? (
          query === '' ? (
            <>
              <CardContainer webinarSuccess={webinarSuccess}></CardContainer>

              <Pagination
                total={webinarSuccess.webinars.total}
                setCurrentPage={setCurrentPage}
                perPage={perPage}
                currentPage={currentPage}
              />
            </>
          ) : (
            <CardContainer webinarSuccess={webinarSuccess}></CardContainer>
          )
        ) : (
          <ErrorText styleType={'NotFound'}>{t('noResult')}</ErrorText>
        )
      ) : (
        <PageLoader></PageLoader>
      )}
    </ScreenContainer>
  )
}

const HeaderHome = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;

  @media ${({ theme }) => theme.device.medium},
    ${({ theme }) => theme.device.large} {
    width: 48%;
  }
  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    width: 100%;
  }
`

const HeaderImage = styled.div`
  width: 55%;
  height: 246px;

  @media ${({ theme }) => theme.device.medium},
    ${({ theme }) => theme.device.large} {
    width: 48%;
    height: 200px;
  }

  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    display: none;
  }
`

export default Home
