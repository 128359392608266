//REGEX
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

//Paswword regex
//At least one uppercase
export const UPPER_REGEX = /(?=.*[A-Z])/

//At least one lower
export const LOWER_REGEX = /(?=.*[a-z])/

//At least one number
export const NUMBER_REGEX = /(?=.*\d)/

//At least one special char
export const SPECIAL_REGEX = /(?=.*[!@#$%^&*:()[\]';.,-])/

// more than 8 chars less than 32
// at least one number
// at least one upepr and lower case
// at lest one special char
export const PASSWORD_REGEX_3 =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*:()[\]';.,-]).{8,32}$/

//PASSWORD_REGEX_3 or empty
export const PASSWORD_EMPTY_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*:()[\]';.,-]).{8,32}$|^$/

//all accentued characters and ponctuation for names . - '
export const nameRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,32}$/u

//accept name with all ponctuation characters
export const webinarTitleRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð !?;:[\](),.'-^_°]+$/u

//accept webinarTitleRegex or empty
export const webinarDescriptionRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð !?;:[\](),.'-^_°\n]*$/u
