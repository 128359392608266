import React from 'react'
import styled from 'styled-components'

//components
import MyLink from '../../components/common/MyLink'
import Img from '../../components/common/Img'
import Title from '../../components/common/Titles/Title'
import TitleContainer from '../../components/common/TitleContainer'

//translation
import { useTranslation } from 'react-i18next'

//images
import IzenciaLogo from '../../assets/images/izenciaLogo300px71px.png'
import SubTitle from '../../components/common/Titles/SubTitle'
import ScreenContainer from '../../components/common/ScreenContainer'
import { useSelector } from 'react-redux'

const NotFound = () => {
  const { t } = useTranslation()

  const auth = useSelector(state => state.authentication.token)

  return (
    <ScreenContainer styleType='Auth'>
      <NotFoundContainer>
        <Img styleType='Auth' src={IzenciaLogo}></Img>
        <TitleContainer styleType='Error'>
          <Title styleType='Auth'>{t('error404')}</Title>
          <SubTitle styleType='Error'>{t('errorLabel')}</SubTitle>
        </TitleContainer>
        {auth ? (
          <MyLink styleType='Auth' bold={t('returnHomeBold')} to='/home'>
            {t('returnHome')}
          </MyLink>
        ) : (
          <MyLink styleType='Auth' bold={t('returnSignInBold')} to='/signin'>
            {t('returnSignIn')}
          </MyLink>
        )}
      </NotFoundContainer>
    </ScreenContainer>
  )
}

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
  }
`
export default NotFound
