import React from 'react'
import ResetPasswordForm from '../../components/ResetPasswordForm'
import ScreenContainer from '../../components/common/ScreenContainer'

//components
import Img from '../../components/common/Img'
import Title from '../../components/common/Titles/Title'
import SubTitle from '../../components/common/Titles/SubTitle'
import TitleContainer from '../../components/common/TitleContainer'
import ContentContainer from '../../components/common/ContentContainer'

//images
import IzenciaLogo from '../../assets/images/izenciaLogo300px71px.png'

//translation
import { useTranslation } from 'react-i18next'

const ResetPassword = props => {
  const { t } = useTranslation()

  return (
    <ScreenContainer styleType={'Auth'}>
      <Img styleType='Auth' src={IzenciaLogo}></Img>
      <ContentContainer>
        <TitleContainer styleType='Auth'>
          <Title styleType='Auth'>{t('resetLabel')}</Title>
          <SubTitle styleType='Auth'>{t('resetDesc')}</SubTitle>
        </TitleContainer>
        <ResetPasswordForm></ResetPasswordForm>
      </ContentContainer>
    </ScreenContainer>
  )
}

export default ResetPassword
