import Axios from 'axios'

// Liste des types
export const GET_ME = 'GET_ME'
export const GET_ME_ERROR = 'GET_ME_ERROR'

export const getMe = _id => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: GET_ME_ERROR,
        payload: null
      })

      const token = getState().authentication.token
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {},
        url: `${process.env.REACT_APP_API_BASE_URL}/auth/me`
      }

      const { data } = await Axios(options)
      dispatch({
        type: GET_ME,
        payload: data
      })
    } catch (error) {
      const errorDetails = {
        status: error.response.status,
        statusText: error.response.statusText
      }
      dispatch({
        type: GET_ME_ERROR,
        payload: errorDetails
      })
    }
  }
}
