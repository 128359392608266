import { DELETE_WEBINAR, DELETE_WEBINAR_ERROR } from '../actions/deleteWebinar'

const initialState = {
  webinar: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DELETE_WEBINAR:
      return {
        ...state,
        webinar: action.payload
      }

    case DELETE_WEBINAR_ERROR:
      return {
        ...state,
        webinars: null,
        error: action.payload
      }

    default:
      return state
  }
}
