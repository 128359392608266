import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from '../Img'

import { Link } from 'react-router-dom'

import PurpleArrow from '../../../assets/images/purpleArrow30px30px.png'

const MyLink = props => {
  const { to, styleType, onClick, bold, children, mail, id } = props

  const selectLink = () => {
    switch (styleType) {
      case 'Card':
        return (
          <CardLinkContainer to={to}>
            <CardText>{children}</CardText>
            <Img styleType='SeeMore' onClick={onClick} src={PurpleArrow}></Img>
          </CardLinkContainer>
        )
      case 'Auth':
        return (
          <AuthLink to={to} onClick={onClick}>
            {children}
            <Bold>{bold}</Bold>
          </AuthLink>
        )

      case 'ForgetPassword':
        return (
          <ForgetPasswordLink to={to} onClick={onClick}>
            {children}
          </ForgetPasswordLink>
        )

      case 'Mail':
        return (
          <MailLink
            to='#'
            onClick={e => {
              window.location = mail
              e.preventDefault()
            }}
          >
            {children}
          </MailLink>
        )

      case 'Consent':
        return (
          <ConsentLink to={to} onClick={onClick} id={id}>
            {children}
          </ConsentLink>
        )

      case 'Policy':
        return (
          <PolicyLink to={to} id={id}>
            {children}
          </PolicyLink>
        )
      default:
        return (
          <BaseLink to={to} onClick={onClick}>
            {children}
          </BaseLink>
        )
    }
  }

  return selectLink()
}

MyLink.propTypes = {
  to: PropTypes.string.isRequired,
  styleType: PropTypes.string,
  onClick: PropTypes.func,
  bold: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const BaseLink = styled(Link)`
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontsize.small};
`

const CardLinkContainer = styled(BaseLink)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.padding.xxsmall};
`

const CardText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`

const AuthLink = styled(BaseLink)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.margin.large};

  @media ${({ theme }) => theme.device.small},
    ${({ theme }) => theme.device.xsmall} {
    margin-top: ${({ theme }) => theme.margin.medium};
    line-height: ${({ theme }) => theme.lineHeight.small};
  }
`

const Bold = styled.p`
  font-weight: bold;
  padding-left: 1ch;
`

const ForgetPasswordLink = styled(BaseLink)`
  text-align: right;
  margin-bottom: ${({ theme }) => theme.margin.medium};
`

const MailLink = styled(BaseLink)`
  font-weight: bold;
`

const ConsentLink = styled(BaseLink)`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.margin.medium};
`

const PolicyLink = styled(BaseLink)`
  font-size: ${({ theme }) => theme.fontsize.mediumsmall};
  color: ${({ theme }) => theme.secondaryTextColor};

  font-weight: 600;
`

export default MyLink
