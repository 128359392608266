import React from 'react'
import NewPasswordForm from '../../components/NewPasswordForm'

//components
import ScreenContainer from '../../components/common/ScreenContainer'
import TitleContainer from '../../components/common/TitleContainer'
import Title from '../../components/common/Titles/Title'
import SubTitle from '../../components/common/Titles/SubTitle'

//translation
import { useTranslation } from 'react-i18next'

const NewPassword = props => {
  const { token } = props
  const { t } = useTranslation()

  return (
    <ScreenContainer token={token}>
      <TitleContainer styleType='Page'>
        <Title>{t('newPassword')}</Title>
        <SubTitle styleType='Primary'>{t('profilDesc')}</SubTitle>
      </TitleContainer>
      <NewPasswordForm token={token}></NewPasswordForm>
    </ScreenContainer>
  )
}

export default NewPassword
