import Axios from 'axios'

// Liste des types
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR'
export const LOGOUT_USER = 'LOGOUT_USER'

// Change le thème
export const login = (userEmail, userPassword, enqueueSnackbar, t, history) => {
  return async dispatch => {
    try {
      dispatch({
        type: LOGIN_USER_ERROR,
        payload: null
      })

      const { data } = await Axios({
        method: 'POST',
        data: {
          email: userEmail,
          password: userPassword
        },
        url: `${process.env.REACT_APP_API_BASE_URL}/auth/login`
      })

      dispatch({
        type: LOGIN_USER,
        payload: {
          token: data.token
        }
      })

      //token
      const jwt = require('jsonwebtoken')

      let decoded = null
      try {
        decoded = jwt.verify(data.token, process.env.REACT_APP_JWT_SECRET)
      } catch (error) {
        //after login decoded can not be expired
        //error handling in private route
      }
      enqueueSnackbar(`${t('loginAlert')} ${decoded.firstname}`, {
        variant: 'success'
      })

      history.push('/home')
    } catch (error) {
      const errorDetails = {
        status: error.response?.status,
        statusText: error.response?.statusText
      }
      if (error.response?.status == null) {
        enqueueSnackbar(t('apiAuthNotOnline'), {
          variant: 'error'
        })
      } else {
        enqueueSnackbar(t('Unauthorized'), {
          variant: 'error'
        })
      }

      dispatch({
        type: LOGIN_USER_ERROR,
        payload: errorDetails
      })
    }
  }
}

export const logout = history => {
  return async dispatch => {
    try {
      dispatch({
        type: LOGOUT_USER,
        payload: {
          token: null
        }
      })
      history.push('/')
    } catch (error) {}
  }
}
