import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TextArea = props => {
  const { styleType, onChange, onBlur, defaultValue, placeholder } = props

  const selectTextArea = () => {
    switch (styleType) {
      default:
        return (
          <BaseTextArea
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            placeholder={placeholder}
          ></BaseTextArea>
        )
    }
  }
  return selectTextArea()
}

TextArea.propTypes = {
  styleType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string
}

const BaseTextArea = styled.textarea`
  font-size: ${({ theme }) => theme.fontsize.small};
  padding: ${({ theme }) => theme.padding.xsmall}
    ${({ theme }) => theme.padding.small};
  color: ${({ theme }) => theme.primaryTextColor};
  text-align: justify;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  margin-top: ${({ theme }) => theme.margin.xxsmall};

  min-height: 17rem;

  /* reset preset */
  border: none;
  resize: none;
  &:focus {
    box-shadow: none;
    outline: 0;
  }

  /* scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background-color: ${({ theme }) => theme.quaternaryBackgroundColor}30;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background-color: ${({ theme }) => theme.quaternaryBackgroundColor}70;
  }

  @media ${({ theme }) => theme.device.large},
    ${({ theme }) => theme.device.xlarge},
    ${({ theme }) => theme.device.xxlarge} {
    min-height: 25rem;
  }
`
export default TextArea
