import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Img = props => {
  const { src, onClick, styleType, direction, open, opacity } = props

  const selectImg = () => {
    switch (styleType) {
      case 'Title':
        return <TitleImage src={src} onClick={onClick}></TitleImage>

      case 'Logo':
        return <Logo open={open} src={src} onClick={onClick}></Logo>

      case 'Pagination':
        return <ArrowPage src={src} onClick={onClick}></ArrowPage>

      case 'NoPagination':
        if (direction === 'left') {
          return <NoPageLeft src={src}></NoPageLeft>
        } else {
          return <NoPageRight src={src}></NoPageRight>
        }

      case 'Thumbnail':
        return <Thumbnail src={src} onClick={onClick}></Thumbnail>

      case 'SeeMore':
        return <SeeMore src={src} onClick={onClick}></SeeMore>

      case 'Auth':
        return <Auth src={src} onClick={onClick}></Auth>

      case 'Eye':
        return <EyeImg src={src} onClick={onClick}></EyeImg>

      case 'PopUp':
        return <PopUpLogoImg src={src} onClick={onClick}></PopUpLogoImg>

      case 'Table':
        return (
          <TableImage
            src={src}
            onClick={onClick}
            opacity={opacity}
          ></TableImage>
        )

      case 'Header':
        return <HeaderImg open={open} src={src} onClick={onClick}></HeaderImg>

      default:
        return <BaseImg src={src} onClick={onClick}></BaseImg>
    }
  }
  return selectImg()
}
Img.propTypes = {
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  styleType: PropTypes.string,
  direction: PropTypes.string,
  open: PropTypes.bool
}

const BaseImg = styled.img`
  width: 50px;
`

const Logo = styled(BaseImg)`
  display: block;
  width: 180px;
  height: auto;
  cursor: pointer;

  @media ${({ theme }) => theme.device.medium},
    ${({ theme }) => theme.device.large} {
    width: 140px;
  }

  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    width: 140px;
    position: ${({ open }) => (open ? 'fixed' : 'initial')};
    left: ${({ theme }) => theme.margin.medium};
    display: ${({ open }) => (open ? 'none' : 'block')};
  }
`

const TitleImage = styled(BaseImg)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.small};
`
const ArrowPage = styled(BaseImg)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

const NoPageLeft = styled(BaseImg)`
  width: 16px;
  height: 16px;
  opacity: 0.5;
  margin-right: ${({ theme }) => theme.padding.small};
`

const NoPageRight = styled(BaseImg)`
  width: 16px;
  height: 16px;
  opacity: 0.5;
  margin-left: ${({ theme }) => theme.padding.small};
`

const Thumbnail = styled(BaseImg)`
  width: auto;
  border-radius: ${({ theme }) => theme.borderRadius.xsmall};
`

const SeeMore = styled(BaseImg)`
  width: 15px;
  height: 15px;
  margin-left: ${({ theme }) => theme.padding.xsmall};
`

const Auth = styled(BaseImg)`
  width: 220px;
  margin-bottom: ${({ theme }) => theme.margin.mediumlarge};

  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    width: 180px;
    margin-bottom: ${({ theme }) => theme.margin.medium};
  }
`

const EyeImg = styled(BaseImg)`
  width: 25px;
  margin: 0 ${({ theme }) => theme.margin.xxsmall};
`

const PopUpLogoImg = styled(BaseImg)`
  width: 12px;
  margin-right: ${({ theme }) => theme.margin.small};
  opacity: 0.75;
`

const TableImage = styled(BaseImg)`
  width: 8px;
  opacity: ${({ opacity }) => opacity};
`

const HeaderImg = styled(BaseImg)`
  display: block;
  width: 180px;
  height: auto;
  cursor: pointer;

  @media ${({ theme }) => theme.device.medium},
    ${({ theme }) => theme.device.large} {
    width: 140px;
  }

  @media ${({ theme }) => theme.device.xsmall},
    ${({ theme }) => theme.device.small} {
    width: 140px;
  }
`
export default Img
